import { Component, OnInit, Input } from '@angular/core';
import { UserData } from '../../../shared/dto/userdata.dto';
import { Response } from '@dmonitor/shared/response';
import { UserService, HomeService, DataService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { BaseComponent, UrlHelper } from '@dmonitor/shared/index';
import { PrivilegeArea } from '@dmonitor/shared/dto';
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.pug',
  styleUrls: ['./user-edit.component.sass'],
})
export class UserEditComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.edit_user';
  public backLink:string;
  @Input() public UserId:string;  
  private user: UserData;
  private rightLink: string = `/${UrlHelper.URL_RIGHTS}`;
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,    
    translateService: TranslateService,
    private homeService: HomeService, 
    private dataService:DataService,
    private userService: UserService) { 
      super(toastController,loadingController,translateService)

  }

  public ngOnInit():void {
    this.userService.getUserData(this.UserId).subscribe(user => {
      this.user = user.result.userData;
      this.dismissLoading(500);
    });
    this.backLink = "/"+this.homeService.getHome();
    this.rightLink = `/${UrlHelper.URL_RIGHTS}/${this.UserId}/${PrivilegeArea.COMPANY}`;
    
  }

  public onClickSubmit(data):void{
    
    if(data.password != null && data.password.length > 0){
      if(data.password != data.passwordConfirm){
        this.showFailedToast(this.translate('msg_passowrd_not_equal'))
        return;
      }
    }
    this.presentLoadingWith(100,() => {
      this.userService.changeUser(data.email, this.UserId, data.password,this.user.viewSettings).subscribe((res:Response<UserData>) => {
        if(res.result.id == this.UserId){  
          this.showSuccessToast(this.translate("msg_success"));
          if(this.dataService.LoggedInUser.id == this.UserId){
            this.dataService.LoggedInUser = res.result;
            this.userService.setUserData(res.result);
            
          }
         
          
    
        }else{
          this.showFailedToast(this.translate('msg_save_error'));
        }
        this.dismissLoading();
      },
      err => {
        this.dismissLoading();
        this.showErrorToast(this.translate('msg_save_error'),err);    
      }
    );

    });
  }

}
