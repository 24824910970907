import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GsmSignalIndicatorComponent } from './gsmsignalindicator.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    exports: [GsmSignalIndicatorComponent],
    declarations: [GsmSignalIndicatorComponent]
  })
  export class GsmSignalIndicatorModule {}