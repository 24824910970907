import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import * as gauges from 'canvas-gauges';
import {HygrometerData, HygrometerSetting, GaugeData} from '@dmonitor/shared/response/sensor/hygrometer';

@Component({
  selector: 'hygrometer-gauge',
  templateUrl: 'gauge.component.pug',
  styleUrls: ['gauge.component.sass'],
})
export class GaugeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() Data: HygrometerData;
  @Input() GaugeData: GaugeData;
  @Input() Settings: HygrometerSetting;
  @Input() GsmSignalStregth:number;
  @Input() BatteryLevel = BatteryLevel.LNULL;
  public SensorPowerSupply = SensorPowerSupply;

  @ViewChild("canvasgauge", { read: ElementRef }) canvasEl: ElementRef;
  public IsError = false;
  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }
  public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }


  private divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }


  private createRadialGauge() {

    var gaugesLib = gauges;
    var radialGauge = new gaugesLib.RadialGauge({
      width: 386,
      height: 386,
      //units: "°C",
      minValue: 0,
      startAngle: 90,
      ticksAngle: 180,
      valueBox: false,
      maxValue: 100,
      majorTicks: [
        "0",
        "20",
        "40",
        "60",
        "80",
        "100"
      ],
      minorTicks: 2,
      strokeTicks: true,
      highlights: [
        {
          "from": 80,
          "to": 100,
          "color": "rgba(200, 50, 50, .75)"
        }
      ],
      colorPlate: "#fff",
      borderShadowWidth: 0,
      borders: false,
      needleType: "arrow",
      needleWidth: 2,
      needleCircleSize: 7,
      needleCircleOuter: true,
      needleCircleInner: false,
      animationDuration: 1500,
      animationRule: "linear",

      type: "radial-gauge",
      title: "%",
      colorTitle: "#000",
      colorNumbers: "#000",
      colorUnits: "#000",
      animateOnInit: true,
      value: this.Data != null ? this.Data.h : 0,
      renderTo: this.canvasEl.nativeElement,

    }).draw();
  }

}
