import { Component, OnInit } from '@angular/core';
import { County } from "@dmonitor/shared/dto/county.dto";
import { ModalController, PopoverController, NavController, ToastController, LoadingController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { filter } from 'rxjs/operators';
import { MoreButtonComponent } from './component/more-button/more-button.component';

import { HomeService, DataService } from '@dmonitor/services/index';
import { CountyDialogComponent } from '@dmonitor/dialogs/countydialog/county-dialog.component';
import { SiteDialogComponent } from '@dmonitor/dialogs/sitedialog/site-dialog.component';
import { PrivilegeArea, Company, Site } from '@dmonitor/shared/dto';
import { UrlHelper, BaseListComponent, ListItem } from '../shared';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-sites',
  templateUrl: 'sites.page.pug',
  styleUrls: ['sites.page.sass']
})
export class SitesPage extends BaseListComponent implements OnInit {


  public BackLink: string = `/${UrlHelper.URL_COMPANIES}`;
  protected TRANSLATE_KEY: string = 'page.sites';
  public CompanyIdFromRoute: string;
  public company: Company;
  public CountyIdFromRoute: string;
  public county: County;
  public Sites: Site[];
  public isHome: boolean;
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService,
    private router: Router,
    public modalController: ModalController,
    dataService: DataService,
    private navigateCtr: NavController,
    private popoverController: PopoverController,
    private homeService: HomeService,
    route: ActivatedRoute
  ) {
    super(toastController, loadingController, translateService, dataService);
    console.log("INIT CONST: Sites");
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.CompanyIdFromRoute = route.snapshot.paramMap.get("companyId");
    this.CountyIdFromRoute = route.snapshot.paramMap.get("countyId");
    this.dataService.SitesForCounty.pipe(filter(v => v != null)).subscribe(
      sites => {
        this.Sites = sites;
      });
  }

  protected isListReadyForRender(): boolean {
    if(this.dataService.LastListOfCompanies.length == 0){
      return false;
    }
    if(this.dataService.LastListOfCounties.length == 0){
      
      return false;
    }
    return true;
  }

  protected onListInit() {
    this.dataService.SetSelectedCompany(this.CompanyIdFromRoute);
    
    this.dataService.SetSelectedCounty(this.CountyIdFromRoute);

    this.log('Site items init');
    this.company = this.dataService.GetSelectedCompany();
    this.county = this.dataService.GetSelectedCounty();
    console.log(this.Sites);
    this.Items = this.createItems(this.Sites, `/${UrlHelper.URL_EQUIPMENTS}/${this.company.id}/${this.county.id}`);
    this.dataService.LastListOfSites = this.Sites;
    this.setPagePrivilege(this.county);
    this.setPageTitle(this.county);
    this.initPage();
    this.refreshComplete();
    this.dismissLoading();


  }

  protected onSuperadminListInit(): void {
    
    this.log('Site items init');
    
    this.dataService.SetSelectedCompany(this.CompanyIdFromRoute);
   
    this.dataService.SetSelectedCounty(this.CountyIdFromRoute);
    this.company = this.dataService.GetSelectedCompany();
    this.county = this.dataService.GetSelectedCounty();
    this.dataService.SiteService.getAllSitesForCompanies([Number(this.company.id)]).subscribe((res) => {
      const sites = res.result.filter(s => s.county_id == this.county.id);
      this.Items = this.createItems(sites, `/${UrlHelper.URL_EQUIPMENTS}/${this.company.id}/${this.county.id}`);
      this.dataService.LastListOfSites = sites;
      this.setPagePrivilege(this.county);
      this.setPageTitle(this.county);
      this.initPage();
      this.refreshComplete();
      this.dismissLoading();
    });
  }

  ngOnInit() {
    this.initList();
    console.log("INIT On INIT: Sites");
    this.log('SITES INIT');
  }

  protected setUserRights() {

  }

  protected onRefresh(): void {
    this.dataService.LoadSites();
  }

  public onItemSelect(item: ListItem) {
    this.dataService.SetSelectedSite(item.id);
    this.navigateCtr.navigateForward(item.url);
  }

  private setPagePrivilege(county: County) {
    if (county == null) {
      this.HasRoleForEdit = false;
      this.HasRoleForAdd = false;
      return;
    }
    this.HasRoleForEdit = this.IsEditRole(county.privilege_id, this.IsSuperadminMode);
    this.HasRoleForAdd = this.IsEditRole(county.privilege_id, this.IsSuperadminMode);
    this.IsSuperadmin = this.dataService.UserService.IsUserSuperadmin();
  }


  private initPage() {
    this.homeService.IsInited.pipe(filter(v => v == true)).subscribe(
      IsInited => {
        this.isHome = this.homeService.isHome(`${UrlHelper.URL_SITES}/${this.company.id}/${this.county.id}`);
        this.BackLink = `/${UrlHelper.URL_COUNTIES}/${this.company.id}`;
      }
    );
  }

  private setPageTitle(county: County): void {
    if (county != null) {
      this.PageTitle = county.name;
    }
    this.ListTitle = this.translate('list_title');

  }


  public async openCountyEditModal() {
    this.presentLoading();
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: CountyDialogComponent,
        componentProps: {
          isNew: false
        }
      });

    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
      if (detail !== null && detail.data == true) {
        this.dataService.LoadCounties().subscribe(loaded => {
          this.county = this.dataService.GetSelectedCounty();
          this.setPageTitle(this.county);
        });
      }
      this.dismissLoading();
    });

    await modal.present();
  }

  public async openSiteAddModal() {
    this.presentLoading();
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: SiteDialogComponent,
        componentProps: {
          isNew: true,
          companyId: this.company.id,
          countyId: this.county.id
        }
      });
    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
      this.dataService.LoadSites();
      this.dismissLoading();
    });

    await modal.present();
  }

  public async openSiteEditModal() {
    this.presentLoading();
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: SiteDialogComponent,
        componentProps: {
          isNew: false
        }
      });

    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
      if (detail !== null && detail.data == true) {


      }
      this.dismissLoading;
    });

    await modal.present();
  }

  public async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: MoreButtonComponent,
      event: ev,
      componentProps: { page: this }
    });
    return await popover.present();
  }
}
