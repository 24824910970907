import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BatteryComponent } from './battery.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    exports: [BatteryComponent],
    declarations: [BatteryComponent]
  })
  export class BatteryModule {}