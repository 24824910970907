import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { EquipmentPageComponent } from './equipment.page';
import { MoreButtonComponent } from './component/more-button/more-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { SensorsModule } from '@dmonitor/sensors/sensors.module';
import { SensorErrorModule } from '../sensors';
import { EquipmentDialogModule } from '@dmonitor/dialogs/equipmentdialog/equipment-dialog.module';
import { LogsDialogModule } from '@dmonitor/dialogs/logsdialog/logs-dialog.module';
import { LogDialogModule } from '@dmonitor/dialogs/logdialog/log-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: EquipmentPageComponent
      }
    ]),
    TranslateModule.forChild(),
    EquipmentDialogModule,
    LogsDialogModule,
    LogDialogModule,
    SensorErrorModule,
    SensorsModule

  ],
  declarations: [EquipmentPageComponent, MoreButtonComponent],
  entryComponents: [MoreButtonComponent]
})
export class EquipmentModule {}
