import { Component, Input, OnInit } from '@angular/core';
import { IntegratorSetting, DailyData, MonthlyData } from '@dmonitor/shared/response/sensor/integrator';
import { HourlyDataDialogComponent } from '../hourlydatadialog/hourlydata-dialog.component';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { DailyDataDialogComponent } from '../dailydatadialog/dailydata-dialog.component';

@Component({
  selector: 'integrator-statistics',
  templateUrl: 'statistics.component.pug',
  styleUrls: ['statistics.component.sass'],
})
export class StatisticsComponent implements OnInit {
  @Input() Settings: IntegratorSetting;
  @Input() EquipmentId: string;
  @Input() DailyData: DailyData[] = [];
  @Input() MonthlyData: MonthlyData[] = [];

  public today: string = '';
  public yesterday: string = '';
  constructor(
    public modalController: ModalController
  ) {

  }
  public ngOnInit(): void {
    const now = new Date(Date.now());
    now.setDate(now.getDate());
    let month = now.getMonth()+1;
    this.today = now.getFullYear() + '-'
      + (month < 10 ? '0' + month : month)
      + "-" + (now.getDate() < 10 ? '0' + (now.getDate()) : now.getDate());
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    month = yesterday.getMonth()+1;
    this.yesterday = yesterday.getFullYear() + '-'
      + (month < 10 ? '0' + month : month)
      + "-" + (yesterday.getDate() < 10 ? '0' + (yesterday.getDate() ) : yesterday.getDate());
  }

  public async openHourlyDataModal(day: string) {
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: HourlyDataDialogComponent,
        componentProps: {
          Settings: this.Settings,
          EquipmentId: this.EquipmentId,
          Day: day
        }
      });

    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
    });
    await modal.present();
  }

  public async openDailyDataModal(d) {
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: DailyDataDialogComponent,
        componentProps: {
          Settings: this.Settings,
          DailyData: this.DailyData,
          MonthlyData: this.MonthlyData
        }
      });

    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
    });
    await modal.present();
  }

}