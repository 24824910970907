import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExpandableComponent } from './expandable.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    exports: [ExpandableComponent],
    declarations: [ExpandableComponent]
  })
  export class ExpandableModule {}