import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams, AlertController, ToastController, NavController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DataService,SiteService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
import { Equipment } from '@dmonitor/shared/dto';
@Component({
  selector: 'app-site-more-button',
  templateUrl: './more-button.component.pug',
  styleUrls: ['./more-button.component.sass'],
})
export class MoreButtonComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.equipment';
  public hasRoleForEdit : Boolean = false;
  public IsSuperadmin:boolean = false;
  public equipment:Equipment;
  constructor(
    private navParams: NavParams,
    private alertController: AlertController,
    translateService: TranslateService,
    toastController: ToastController,
    loadingController:LoadingController,
    private navigateCtr:NavController,
    private dataService: DataService,
    private popoverController: PopoverController) {
      super(toastController,loadingController,translateService);
  }
 

  ngOnInit() {
    this.hasRoleForEdit = this.navParams.data.page.HasRoleForEdit;
    this.IsSuperadmin = this.dataService.UserService.IsUserSuperadmin();
    this.equipment = this.dataService.GetEquipment(this.dataService.EquipmentService.GetSelected());
  }

  public openModal(){
    this.navParams.data.page.openEquipmentEditModal();
    this.popoverController.dismiss();
  }
  public opneLogsModal(){
    this.navParams.data.page.openEquipmentLogsModal();
    this.popoverController.dismiss();
  }

  public async setActive(active:boolean){
    
    let key = "activate_confirmation_dialog";
    if(active ==false){
      key = "inactivate_confirmation_dialog";
    }
    let header = '';
    let subHeader = '';
    let message = '';
    let buttons = [
      {
        text: this.translate(`${key}.button_cancel`),
        role: 'cancel',
        
        handler: () => {
          this.popoverController.dismiss();          
        }
      }, {
        text: this.translate(`${key}.button_ok`),
        cssClass: 'dm-secondary',
        color: "danger",
        handler: () => {
          this.equipment.active = active;
          this.dataService.EquipmentService.edit(this.equipment).subscribe(res => {
            this.dataService.Initialize();
            this.showSuccessToast(this.translate(`${key}.msg_success`));
            this.popoverController.dismiss();
            this.navigateCtr.back();
          },err => {
            debugger;
            this.showErrorToast(this.translate(`${key}.msg_error`),err);
            this.popoverController.dismiss();
          });
        }
      }
    ];
    header = this.translate(`${key}.header`);
    subHeader = this.translate(`${key}.sub_header`);
    message = this.translate(`${key}.msg`);
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons
    });
    await alert.present();
  }

  public reload(){
    document.location.href='/';
  }

  public async delete(){
    let header = '';
    let subHeader = '';
    let message = '';
    let buttons = [
      {
        text: this.translate('delete_confirmation_dialog.button_cancel'),
        role: 'cancel',
        
        handler: () => {
          this.popoverController.dismiss();          
        }
      }, {
        text: this.translate('delete_confirmation_dialog.button_ok'),
        cssClass: 'dm-secondary',
        color: "danger",
        handler: () => {
          this.dataService.EquipmentService.delete(this.dataService.EquipmentService.GetSelected()).subscribe(res => {
            this.dataService.Initialize();
            this.showSuccessToast(this.translate("delete_confirmation_dialog.msg_success"));
            this.popoverController.dismiss();
            this.navigateCtr.back();
          },err => {
            debugger;
            this.showErrorToast(this.translate('delete_confirmation_dialog.msg_error'),err);
            this.popoverController.dismiss();
          });
        }
      }
    ];

    
    if(this.navParams.data.page.isSiteList == true){
      
  
      
    }else{
      header = this.translate('delete_confirmation_dialog.header');
      subHeader = this.translate('delete_confirmation_dialog.sub_header');
      message = this.translate('delete_confirmation_dialog.msg');
    } 
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons
    });
    await alert.present();
  }

}
