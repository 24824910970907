import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HygrometerComponent } from './hygrometer.component';
import { SensorErrorModule } from '@dmonitor/sensors/sensorerror/sensorerror.module';
import { SensorLoadingModule } from '@dmonitor/sensors/sensorloading/sensorloading.module';
import { ParametersComponent } from './parameters/parameters.component';
import { GaugeComponent } from './gauge/gauge.component';
import { LineargaugeComponent } from './lineargauge/lineargauge.component';
import { DailydataComponent } from './dailydata/dailydata.component';
import { DisplayComponent } from './display/display.component';
import { ExpandableModule } from '../expandable/expandable.module';
import { TranslateModule } from '@ngx-translate/core';
import { StatisticsComponent } from './statistics/statistics.component';
import { HourlyDataDialogComponent } from './hourlydatadialog/hourlydata-dialog.component';
import { DailyDataDialogComponent } from './dailydatadialog/dailydata-dialog.component';
import { ParametersEditComponent } from './parametersedit/parameters.edit.component';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxChartsModule} from '@swimlane/ngx-charts';
import { DailydataGraphComponent } from './dailydatagraph/dailydatagraph.component';
import { BatteryModule } from '../battery/battery.module';
import { MetadataComponent } from './metadata/metadata.component';
import { GsmSignalIndicatorModule } from '../gsmsignalindicator/gsmsignalindicator.module';
import { NgxLiquidGaugeModule } from 'ngx-liquid-gauge';
import {HourlydataGraphComponent} from '@dmonitor/sensors/hygrometer/hourlydatagraph/hourlydatagraph.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      SensorErrorModule,
      BatteryModule,
      GsmSignalIndicatorModule,
      SensorLoadingModule,
      NgxLiquidGaugeModule,
      TranslateModule.forChild(),
      ResponsiveModule.forRoot(),
      NgxChartsModule,
      ExpandableModule
    ],
    exports: [HygrometerComponent,ParametersEditComponent,GaugeComponent,LineargaugeComponent],
    declarations: [
      HygrometerComponent,
      ParametersComponent,
      MetadataComponent,
      GaugeComponent,
      LineargaugeComponent,
      DailydataComponent,
      DisplayComponent,
      StatisticsComponent,
      DailyDataDialogComponent,
      DailydataGraphComponent,
      ParametersEditComponent,
      HourlyDataDialogComponent,
      HourlydataGraphComponent,
    ],
    entryComponents: [HourlyDataDialogComponent,DailyDataDialogComponent]
  })
  export class HygrometerModule {}
