import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IntegratorComponent } from './integrator.component';
import { SensorErrorModule } from '@dmonitor/sensors/sensorerror/sensorerror.module';
import { SensorLoadingModule } from '@dmonitor/sensors/sensorloading/sensorloading.module';
import { ParametersComponent } from './parameters/parameters.component';
import { GaugeComponent } from './gauge/gauge.component';
import { DailydataComponent } from './dailydata/dailydata.component';
import { DisplayComponent } from './display/display.component';
import { ExpandableModule } from '../expandable/expandable.module';
import { TranslateModule } from '@ngx-translate/core';
import { StatisticsComponent } from './statistics/statistics.component';
import { HourlyDataDialogComponent } from './hourlydatadialog/hourlydata-dialog.component';
import { DailyDataDialogComponent } from './dailydatadialog/dailydata-dialog.component';
import { ParametersEditComponent } from './parametersedit/parameters.edit.component';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DailydataGraphComponent } from './dailydatagraph/dailydatagraph.component';
import { BatteryModule } from '../battery/battery.module';
import { MetadataComponent } from './metadata/metadata.component';
import { GsmSignalIndicatorModule } from '../gsmsignalindicator/gsmsignalindicator.module';
import { NgxLiquidGaugeModule } from 'ngx-liquid-gauge';
import { SimpleGaugeComponent } from './simple-gauge/simple-gauge.component';
import { MonitoringComponent } from './monitoringview/monitoring.component';
import { MonthlyDataGraphComponent } from './monthlydatagraph/monthlydatagraph.component';
import { ReadingsComponent } from './readings/readings.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      SensorErrorModule,
      BatteryModule,
      GsmSignalIndicatorModule,
      SensorLoadingModule,
      NgxLiquidGaugeModule,
      TranslateModule.forChild(),
      ResponsiveModule.forRoot(),
      NgxChartsModule,
      ExpandableModule
    ],
    exports: [IntegratorComponent,ParametersEditComponent, GaugeComponent, SimpleGaugeComponent],
    declarations: [
      IntegratorComponent,
      ParametersComponent,
      MetadataComponent,
      GaugeComponent,
      DailydataComponent,
      SimpleGaugeComponent,
      DisplayComponent,
      StatisticsComponent,
      MonitoringComponent,
      DailyDataDialogComponent,
      DailydataGraphComponent,
      MonthlyDataGraphComponent,
      ParametersEditComponent,
      ReadingsComponent,
      HourlyDataDialogComponent],
    entryComponents: [HourlyDataDialogComponent,DailyDataDialogComponent]
  })
  export class IntegratorModule {}