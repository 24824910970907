import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { UrlHelper } from ".";
import { DataService } from "../services";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router,private dataService:DataService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe( tap(() => {},
        (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
           return;
          }
          this.router.navigate([`/${UrlHelper.URL_LOGIN}`]);
          this.dataService.AuthService.$IsLoggedIn.next(false);
        }
      }));
    }
}