export enum SensorsType {
    MOCK = -1,
    UNKNOWN = 0,
    INTEGRATOR,
    THERMOMETER,
    HYGROMETER,
    STATUSINDICATOR,
    TANK,
    GROUPEDSENSOR

}
export enum SensorPowerSupply{
    UNKNOWN = 0,
    BATTERY = 1,
    WIRED =2
}

export enum DeviceType{
    HOT_WATER = "hot_water",
    COLD_WATER = "cold_water",
    UNKNOWN = ""
}

export enum DeviceColors{
    HOT_WATER_WAVE_COLOR = "#FFDDDD",
    HOT_WATER_TEXT_COLOR = "#FF4444",
    HOT_CIRCLE_COLOR = "#FF7777",
    COLD_WATER_WAVE_COLOR = "#4c8dff",
    COLD_WATER_TEXT_COLOR = "#1e1ff3",
    COLD_CIRCLE_COLOR = "#1e1ff3",
    HOT_GRAPH_COLOR = "#FFDDDD",
    COLD_GRAPH_COLOR = "#4c8dff"
}
