import { Component, OnInit, HostListener } from '@angular/core';
import { LoadingController, ToastController, PopoverController, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Equipment, Site, PrivilegesEnum } from '@dmonitor/shared/dto';
import { IntegratorResponse } from '@dmonitor/shared/response/sensor/integrator';
import { SensorsType } from '@dmonitor/sensors/index';
import { HomeService, DataService } from '@dmonitor/services/index';
import { Subject } from 'rxjs';
import { BaseComponent } from '@dmonitor/shared/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlHelper } from '../shared';
import { MoreButtonComponent } from './component/more-button/more-button.component';
import { EquipmentDialogComponent } from '@dmonitor/dialogs/equipmentdialog/equipment-dialog.component';
import { LogsDialogComponent } from '@dmonitor/dialogs/logsdialog/logs-dialog.component';
@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.page.pug',
  styleUrls: ['./equipment.page.sass']
})
export class EquipmentPageComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.equipment.sensor';
  public BackLink: string = `/${UrlHelper.URL_EQUIPMENTS}`;
  public companyId: string;
  public countyId: string;
  public siteId: string;
  public site: Site;
  public HasRoleForEdit: Boolean = false;
  public HasRoleForView: Boolean = false;
  public equipmentId: string;
  public equipmentData: IntegratorResponse;
  public equipment: Equipment;
  public SensorsTypes: typeof SensorsType = SensorsType;
  public lastRefresh: number;
  public refreshSubject: Subject<void> = new Subject<void>();
  public silentRefreshSubject: Subject<void> = new Subject<void>();
  public IsError: boolean = false;
  public Error: Error = null;
  public isHome: boolean;
  protected refreshInterval;

  constructor(
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService,
    private modalController: ModalController,
    private dataService: DataService,
    private popoverController: PopoverController,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(toastController, loadingController, translateService);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.companyId = this.route.snapshot.paramMap.get("companyId");
    this.countyId = this.route.snapshot.paramMap.get("countyId");
    this.siteId = this.route.snapshot.paramMap.get("siteId");
    requestAnimationFrame(async () => await this.requestAnimationFrameRefreshEquipmentData());
    this.equipmentId = this.route.snapshot.paramMap.get("equipmentId");
    

  }
  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    console.log("FOCUS");
    if (this.isRefreshNeeded()) {
      this.setEquipmentData(true);
    }

  }

  public ionViewWillEnter():void{
    console.log("SET REFRESH INTERVAL");
    this.refreshInterval = setInterval(() => {
      console.log("REFRESH INTERVALL");
      if (this.isRefreshNeeded()) {
        this.setEquipmentData(false);
      }

    }, 1000 * 30);
  }

  public ionViewWillLeave():void{
    if(this.refreshInterval != null){
      console.log("REFRESH INTERVALL STOP");
      clearInterval(this.refreshInterval);
    }
  }

  public ngOnInit(): void {
    this.log("EQUIPMENT INIT");
    if(this.companyId == "0" && this.countyId == "0" && this.siteId == "0"){
      this.BackLink = `/${UrlHelper.URL_EQUIPMENTS_LIST}`;
    }else{
      this.BackLink = `/${UrlHelper.URL_EQUIPMENTS}/${this.companyId}/${this.countyId}/${this.siteId}`;
    }
    

    this.dataService.EquipmentService.SelectionChanged().subscribe(id => {
      this.log("EQUIPMENT INIT2");
      this.setEquipmentData(false);
      if (this.equipment != null) {
        this.HasRoleForEdit = this.equipment.privilege_id == `${PrivilegesEnum.EDIT}` ? true : false;
        this.HasRoleForView = this.equipment.privilege_id == `${PrivilegesEnum.VIEW}` ? true : false;
        if (!this.HasRoleForEdit && !this.HasRoleForView) {
          this.Error = new Error(this.translate("error.no_privilegs"));
          this.IsError = true;
          return;
        }
      }

    });    


    this.isHome = this.homeService.isHome(`${UrlHelper.URL_EQUIPMENT}/${this.companyId}/${this.countyId}/${this.siteId}/${this.equipmentId}`);

    this.setEquipmentData(true);


  }

  public requestAnimationFrameRefreshEquipmentData() {
    console.log("REFRESH ANIMATION");
    if (this.isRefreshNeeded()) {
      this.setEquipmentData(true);
    }
  }

  protected upadateLastRefresh(): void {
    this.lastRefresh = Date.now();
  }

  protected isRefreshNeeded(): boolean {
    if (this.equipment == null) {
      return true;
    }
    if (this.site == null) {
      return true;
    }
    const now = Date.now();
    if (this.lastRefresh == null) {
      return false;
    }
    console.log(now - this.lastRefresh);
    if (now - this.lastRefresh > 60) {
      return true;
    }
    return false;
  }

  private async setEquipmentData(silent: boolean) {
    this.log(`setEquipmentdata: ${this.equipmentId}`);
    this.equipment = this.dataService.GetEquipment(this.equipmentId);
    this.site = this.dataService.GetSiteFromRaw(this.equipment.site_id);
    console.log(this.equipment);
    if (this.equipment == null) {
      return;
    }
    if (this.equipment.type == null || this.equipment.type == SensorsType.UNKNOWN.toString()) {
      this.Error = new Error(this.translate("error.type_not_found"));
      this.IsError = true;
      return;
    }

    this.upadateLastRefresh();
    if (silent == false) {

      this.refreshSubject.next();
    } else {
      this.silentRefreshSubject.next();
    }

  }

  public reload(): void {
    document.location.reload();
  }
  public async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: MoreButtonComponent,
      event: ev,
      componentProps: { page: this }
    });
    return await popover.present();
  }

  public async openEquipmentEditModal() {
    this.presentLoading();

    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: EquipmentDialogComponent,
        componentProps: {
          isNew: false,
          companyId: this.companyId,
          countyId: this.countyId,
          siteId: this.siteId,
          equipmentId: this.equipmentId
        }
      });
    modal.onDidDismiss().then((detail: OverlayEventDetail) => {

      if (detail !== null && detail.data == true) {
        this.dataService.LoadSites();
      }
      this.dismissLoading();
    });

    await modal.present();

  }
  public async openEquipmentLogsModal() {
    this.presentLoading();

    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: LogsDialogComponent,
        componentProps: {
          type:2,
          equipmentId: this.equipmentId
        }
      });
    modal.onDidDismiss().then((detail: OverlayEventDetail) => {

      if (detail !== null && detail.data == true) {
        this.dataService.LoadSites();
      }
      this.dismissLoading();
    });

    await modal.present();

  }
}
