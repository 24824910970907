import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from '@dmonitor/services/data.service';
import { UrlHelper } from '@dmonitor/shared/index';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private dataService: DataService,
    private cookiService: CookieService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    console.log(route);

    let authInfo = {
      authenticated: true
    };
    const token = this.cookiService.get('token');
    if (token == "") {
      authInfo.authenticated = false;
    }
    //debugger;
    if (!authInfo.authenticated) {
      this.router.navigate([`${UrlHelper.URL_LOGIN}`]);
      return false;
    }
    this.dataService.AuthService.$IsLoggedIn.next(true);
    return true;
  }
}