import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Equipment } from '@dmonitor/shared/dto';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
import { SensorsType } from '@dmonitor/sensors/index';

@Component({
  selector: 'app-equipment-dialog',
  templateUrl: './equipment-dialog.component.pug',
  styleUrls: ['./equipment-dialog.component.sass'],
})
export class EquipmentDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.equipment_dialog';
  @Input() isNew: boolean = false;
  @Input() equipmentId: string;
  @Input() siteId: string;
  public SensorsTypes: typeof SensorsType = SensorsType;
  private equipment: Equipment;
  public title = '';
  //public sensorType = 0;
  public validation_messages = [
    { type: 'required', message: 'Berendezés név kötelező' },
    { type: 'minlength', message: 'Berendezés név hossza minimum 3 karakter hosszú.' },
  ];

  public name = new FormControl('',
    Validators.compose([
      Validators.required,
      Validators.minLength(3)
    ])
  );
  public type = new FormControl('1',
    Validators.compose([
      Validators.required,
    ])
  );
  public latitude = new FormControl();
  public longitude = new FormControl();

  public form = new FormGroup({
    name: this.name,
    type: this.type,
    latitude: this.latitude,
    longitude: this.longitude
  });

  constructor(
    public modalController: ModalController,
    translateService: TranslateService,
    loadingController: LoadingController,
    private dataService: DataService,
    toastController: ToastController
  ) {
    super(toastController, loadingController, translateService);
  }


  public ngOnInit() {
    this.validation_messages = [
      { type: 'required', message: this.translate('msg_error_required') },
      { type: 'minlength', message: this.translate('msg_error_min_character') },
    ];
    this.dismissLoading(1000);

    if (this.isNew) {
      this.title = this.translate('title_add');
      return;
    }
    this.title = this.translate('title_modify');
    this.equipment = this.dataService.GetEquipment(this.equipmentId);
    this.name.setValue(this.equipment.name);
    this.latitude.setValue(this.equipment.latitude);
    this.longitude.setValue(this.equipment.longitude);
  }

  public dismiss(success: Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(data) {
    this.presentLoadingWith(1000, () => {
      if (this.isNew) {
        this.add();
      } else {
        this.edit();
      }
    });
  }

  private add(): void {
    const equipment: Equipment = {
      name: this.name.value,
      serial_num: '',
      type: this.type.value,
      address: '',
      site_id: this.siteId,
      longitude: this.longitude.value,
      latitude: this.latitude.value,
      active:true,
      id: null,

    }
    this.dataService.EquipmentService.add(equipment).subscribe(
      (res: any) => {
        this.dismissLoading();
        if (res.result.success == true) {
          this.showSuccessToast(this.translate('msg_success'));
          this.dataService.ReloadUserRights().subscribe(() =>{
            this.dataService.ReloadEquipments();
          })
          this.dismiss(true);
        } else {
          this.showFailedToast(this.translate('msg_save_error'));
        }
      },
      (error: any) => {
        this.dismissLoading();
        debugger;
        this.showErrorToast(this.translate('msg_save_error'), error);
      }
    );
  }


  private edit(): void {
    //debugger;
    this.equipment.name = this.name.value;
    this.equipment.latitude = this.latitude.value;
    this.equipment.longitude = this.longitude.value;
    this.dataService.EquipmentService.edit(this.equipment).subscribe(
      (res: any) => {
        if (res.result.success == true) {

          this.showSuccessToast(this.translate('msg_success_modify'));

          this.dismiss(true);
        } else {
          this.showFailedToast(this.translate('msg_save_error_modify'));
          this.dismiss(true);
        }
      },
      (error: any) => {
        this.dismissLoading();
        this.showErrorToast(this.translate('msg_save_error_modify'), error);
      }
    );
  }


}
