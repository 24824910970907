import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.pug',
  styleUrls: ['./more-button.component.sass'],
})
export class MoreButtonComponent implements OnInit {

  constructor() {

  }
  
  ngOnInit() {

  }


  public reload(){
    document.location.href='/';
  }

}
