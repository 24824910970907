import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Company, PrivilegesEnum } from '@dmonitor/shared/dto';
import { DefaultResponse } from '@dmonitor/shared/default-response';
import { SelectableServiceBase } from './selectable.service.base';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class CompanyService extends SelectableServiceBase<string> {
  Initialize(ids: string[]): void {
    this.Inited().next(true);
  }

  private companiesForRightsIsInitedSubject = new BehaviorSubject(false);
  public companiesForRights: Array<Company> = [];
  
  constructor(
    protected http: HttpClient,
    protected cookiService: CookieService
    ) { 
    super();
  }

  public getCompanyIds():Array<number>{
    //return this.companies.map(comp => parseInt(comp.id));
    return null;
  }

  public getCompaniesByCompanyArray(companyIds:Array<number>){
    return this.http.get<any>(`${environment.apiUrl}/company?cid=${JSON.stringify(companyIds)}&apikey=${environment.apiKey}`, this.getHttpOptions());
  }

  public getAllCompanies(){
    return this.http.get<any>(`${environment.apiUrl}/company/all?apikey=${environment.apiKey}`, this.getHttpOptions());
  }


  public add(companyName:string){
    
    
    const postData = JSON.stringify({
      name: companyName
    });

    return this.http.post<object>(`${environment.apiUrl}/company/add?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public edit(id:string, companyName:string){
   
    const postData = JSON.stringify({
      id: id,
      name: companyName
    });

    return this.http.post<object>(`${environment.apiUrl}/company/edit?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public delete(id:string){
    const postData = JSON.stringify({
      id: id
    });
    return this.http.post<object>(`${environment.apiUrl}/company/delete?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

  public initCompaniesForRights(){
    this.getAllCompanies().subscribe(
      (res: DefaultResponse) => {
        this.companiesForRights = res.result;
        this.companiesForRightsIsInitedSubject.next(true);
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
