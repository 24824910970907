import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from '@dmonitor/shared/dto';
import { DataService } from '@dmonitor/services/index';
import { SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import {ThermometerParameters, ThermometerSetting} from '@dmonitor/shared/response/sensor/thermometer';

@Component({
  selector: 'thermometer-parameters-edit',
  templateUrl: 'parameters.edit.component.pug',
  styleUrls: ['parameters.edit.component.sass'],
})
export class ParametersEditComponent implements OnInit {
  @Input() Equipment: Equipment;
  public Settings: ThermometerSetting;
  public Parameters: ThermometerParameters;
  public Type: number;
  public IsLoaded: boolean = false;

  constructor(
    private dataService: DataService
  ) { }

  public ngOnInit(): void {
    this.dataService.EquipmentService.getThermometerData(this.Equipment.id).subscribe(
      (res) => {
        this.Settings = res.result.Settings;
        if (this.Settings == null) {
          this.Settings = {
            last_update: "",
            device_type: "",
            power_supply : SensorPowerSupply.UNKNOWN 
          }
        }

        //this.Type = 0;
        this.Parameters = res.result.Parameters;
        this.IsLoaded = true;



      },
      (err) => {
        this.IsLoaded = false;
      }

    );
    // console.log(this.Parameters);
  }


  public isEditable(parameterName: string): boolean {
    switch (parameterName) {
      case 'address':
        return true;
      default:
        return false;
    }
  }
  public parseFloat(num: string) {
    return parseFloat(num).toPrecision(6);
  }

  public divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }

  private getDeviceType(type: number): string {
    if (type == 0) {
      return "0...20 mA";
    }
    if (type == 1) {
      return "4...20 mA";
    }
    if (type == 2) {
      return "Frekvencia";
    }

    return "";
  }

}
