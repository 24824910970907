import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, OnChanges, Renderer2 } from '@angular/core';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import * as gauges from 'canvas-gauges';
import {TankData, GaugeData, TankSetting} from '@dmonitor/shared/response/sensor/tank';

@Component({
  selector: 'tank-gauge',
  templateUrl: 'tankgauge.component.pug',
  styleUrls: ['tankgauge.component.sass'],
})
export class TankgaugeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() Data: TankData;
  @Input() GaugeData: GaugeData;
  @Input() Settings: TankSetting;
  @Input() GsmSignalStregth:number;
  @Input() BatteryLevel = BatteryLevel.LNULL;
  public SensorPowerSupply = SensorPowerSupply;

  @ViewChild("cylinderElement") cylinderElement: ElementRef<HTMLInputElement>;
  @ViewChild("waterElement") waterElement: ElementRef<HTMLInputElement>;
  @ViewChild("canvasgauge", { read: ElementRef }) canvasEl: ElementRef;
  public IsError = false;
  constructor(private renderer: Renderer2) {}
  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    this.createTankGauge();
    if (this.canvasEl != null) {
      this.createLinearGauge();

    }
    
  }
  public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.createTankGauge();
    if (this.canvasEl != null) {
      this.createLinearGauge();

    }
  }


  private divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }

  private createTankGauge(){
    if(this.waterElement !== undefined && this.cylinderElement !== undefined){
      var amount = 0;
      var unit = this.Settings.unit;
      if(this.Data.l != null && this.Data.l !== undefined){
        if(unit !== "%"){

          var currentLevel = parseInt(this.Data.l);
          var maxLevel = parseInt(this.Settings.capacity);
          amount = (currentLevel/maxLevel);
          
        }else{
          amount = (amount / 100);
        }
      }
      var height = 250*amount;
      this.renderer.setStyle(this.waterElement.nativeElement, 'height', height+"px");
    }
  }
  private createLinearGauge(){
    var gaugesLib = gauges;
    var unit = this.Settings.unit;
    var maxLevel = 100;
    var ticks = [];
    var level = 0;
    var increment = 10;
    var currentLevel = parseInt(this.Data.l);
    if(unit !== "%"){
      maxLevel = parseInt(this.Settings.capacity);      
      increment = 20;
      if(maxLevel > 300){
        increment = 50;
      }
    }
    while(true){
      if(level >= maxLevel){
        ticks.push(maxLevel.toString());
        break;
      }
      ticks.push(level.toString());
      level += increment;
    }
    
    var radialGauge = new gaugesLib.LinearGauge({
      renderTo: this.canvasEl.nativeElement,
      width: 120,
      height: 375,
      units: unit,
      minValue: 0,
      maxValue: maxLevel,
      majorTicks: ticks,
      minorTicks: 2,
      strokeTicks: true,
      highlights: [
          {
              "from": (maxLevel-40),
              "to": maxLevel,
              "color": "rgba(200, 50, 50, .75)"
          }
      ],
      colorPlate: "#fff",
      borderShadowWidth: 0,
      borders: false,
      needleType: "arrow",
      needleWidth: 2,
      animationDuration: 1500,
      animationRule: "linear",
      tickSide: "left",
      numberSide: "left",
      needleSide: "left",
      barWidth: 0,
      barStrokeWidth: 0,
      barBeginCircle: false,
      value: currentLevel
    }).draw();
  }

}
