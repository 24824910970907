import { Component, Input, OnInit } from '@angular/core';
import { DailyData } from '@dmonitor/shared/response/sensor/statusindicator';

@Component({
  selector: 'statusindicator-dailydata',
  templateUrl: 'dailydata.component.pug',
  styleUrls: ['dailydata.component.sass'],
})
export class DailydataComponent implements OnInit {
  @Input() DailyData: DailyData[] = [

  ];
  public expanded = true;
  public ngOnInit(): void {
  }
  public expandItem(item): void {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }
}
