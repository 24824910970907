export class UrlHelper {
    public static readonly  URL_COUNTIES = "counties";
    public static readonly  URL_SITES = "sites";
    public static readonly  URL_SITE = "site";
    public static readonly  URL_COMPANIES = "companies";
    public static readonly  URL_RIGHTS = "rights";
    public static readonly  URL_USER = "user";
    public static readonly  URL_EQUIPMENTS = "equipments";
    public static readonly  URL_EQUIPMENT = "equipment";
    public static readonly  URL_LOGIN = "login";
    public static readonly  URL_EQUIPMENTS_LIST = "equipmentslist";
}