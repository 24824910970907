import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@dmonitor/shared/index';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from '@dmonitor/services/index';
import {StatusindicatorDailyData, StatusindicatorSetting} from '@dmonitor/shared/response/sensor/statusindicator';
import {forEach} from '@angular-devkit/schematics';

@Component({
  selector: 'statusindicator-hourlydata-dialog',
  templateUrl: 'hourlydata-dialog.component.pug',
  styleUrls: ['hourlydata-dialog.component.sass'],
})
export class HourlyDataDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = "page.equipment.statusindicator.hourlydata";
  @Input() Settings: StatusindicatorSetting;
  @Input() Day: string;
  @Input() EquipmentId: string;
  public Data: StatusindicatorDailyData[] = [];
  constructor(
    public modalController: ModalController,
    toastController: ToastController,
    loadingController: LoadingController,
    private equipmentService: EquipmentService,
    translateService: TranslateService) {
    super(toastController, loadingController, translateService);
  }
  public ngOnInit(): void {
    this.presentLoading();
    console.log(`EquipmentId: ${this.EquipmentId} day: ${this.Day}`);
    this.equipmentService.getStatusindicatorDailyStatisticData(this.EquipmentId, this.Day).subscribe(
      (res) => {
        for(let i = 0; i < res.result.length; i++) {
          res.result[i].state_changed = res.result[i].state_changed.substr(11);
          this.Data.push(res.result[i]);
        }
        console.warn(this.Data);
        this.dismissLoading();
      },
      (err) => {
        this.dismissLoading();
        this.showErrorToast('', err);
      });
  }

  public dismiss() {
    this.modalController.dismiss();
  }


}
