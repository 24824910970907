import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Response, WhoamiResponse, GetUserDataResponse } from '@dmonitor/shared/response';
import { UserData } from '../shared/dto/userdata.dto';
import { InitableInterface } from './initable.interface';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService implements InitableInterface {
  private isInitedSubject = new BehaviorSubject(false);
  private userData: UserData;
  constructor(
    protected http: HttpClient,
    protected cookiService: CookieService) 
    { 
      super();
    }

  public Inited(): BehaviorSubject<boolean> {
    return this.isInitedSubject;
  }

  public IsInited(): Observable<boolean> {
    return this.isInitedSubject.pipe(filter(v => v == true));
  }

  Initialize(...args: any[]): void {
    throw new Error("Method not implemented.");
  }

  public getActualUserData(): UserData {
    return this.userData;
  }

  public IsUserSuperadmin(): boolean {
    if (this.userData == null) {
      return false;
    }
    return (this.userData.superadmin == "1");
  }



  public whoami(): Observable<Response<WhoamiResponse>> {
    return this.get('/whoami');
  }

  
  
  public changePassword(userId: Number, password: string) {
    const httpOptions = {
      headers: this.getHttpHeaders()
      //withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
    };
        const postData = JSON.stringify({
      id: userId,
      password: password
    });
    return this.http.post<object>(`${environment.apiUrl}/changePassword?apikey=${environment.apiKey}`, postData, httpOptions);
  }


  public changePrivilege(userId: string, itemId: string, areaId: string, privilegeId: string) {
    const httpOptions = {
      headers: this.getHttpHeaders()
      //withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
    };
    const postData = JSON.stringify({
      user_id: userId,
      item_id: itemId,
      area_id: areaId,
      privilege_id: privilegeId
    });
    return this.http.post<object>(`${environment.apiUrl}/changePrivilege?apikey=${environment.apiKey}`, postData, httpOptions);
  }

  public changeUser(email: string, userId: string = null, password: string = null,viewSettings = null):Observable<Response<UserData>> {
    const httpOptions = {
      headers: this.getHttpHeaders()
      //withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
    };
    const postData = JSON.stringify({
      id: userId,
      email: email,
      password: password,
      viewSettings: viewSettings
    });
    return this.http.post<Response<UserData>>(`${environment.apiUrl}/changeUser?apikey=${environment.apiKey}`, postData, httpOptions);
  }

  public getUserData(userId: string): Observable<Response<GetUserDataResponse>> {

    return this.get(`/getUserData?uid=${userId}`);
  }


  public logout() {
    return this.http.get<any>(`${environment.apiUrl}/logout`);
  }

  public getAllUsers() {
    return this.get(`/getAllUsers`);
  }

/*
  public checkUserPrivilegeById(privilegeArea: Number, id: number): string {
    var role = PrivilegesEnum.NONE;
    if (this.privilegesObj == null) {
      console.log("PRIVILEGES OBJ NOT INITED");
      return role.toString();
    }
    switch (privilegeArea) {
      case PrivilegeArea.EQUIPMENT:
        if (this.privilegesObj.equipment == null) {
          console.log("PRIVILEGES OBJ EQUIPMENT NOT INITED");
          return;
        }
        this.privilegesObj.equipment.forEach((ep: EquipmentPrivilege) => {
          if (ep.equipment_id == id) {
            role = ep.privilege_id;
            return;
          }
        });
        break;
      case PrivilegeArea.SITE:
        if (this.privilegesObj.site == null) {
          console.log("PRIVILEGES OBJ SITE NOT INITED");
          return;
        }
        this.privilegesObj.site.forEach((sp: SitePrivilege) => {
          if (sp.site_id == id) {
            role = sp.privilege_id;
            return;
          }
        });
        break;
      case PrivilegeArea.COUNTY:
        if (this.privilegesObj.county == null) {
          console.log("PRIVILEGES OBJ COUNTY NOT INITED");
          return;
        }
        this.privilegesObj.county.forEach((cp: CountyPrivilege) => {
          if (cp.county_id == id) {
            role = cp.privilege_id;
            return;
          }
        });
        break;
      case PrivilegeArea.COMPANY:
        if (this.privilegesObj.company == null) {
          console.log("PRIVILEGES OBJ COMPANYű NOT INITED");
          return;
        }

        this.privilegesObj.company.forEach((cp: CompanyPrivilege) => {
          if (cp.company_id == id) {
            role = cp.privilege_id;
            return;
          }
        });
        break;
    }
    return role.toString();
  }
  public setPrivileges(privileges: Privileges) {
    this.privilegesObj = privileges;
  }

*/
  public setUserData(userData: UserData) {
    this.userData = userData;
  //  this.setPrivileges(privileges);
    this.isInitedSubject.next(true);

  }
}
