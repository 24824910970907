import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Company, PrivilegesEnum } from '@dmonitor/shared/dto';
import { DefaultResponse } from '@dmonitor/shared/default-response';
import { SelectableServiceBase } from './selectable.service.base';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class MonitoringService extends SelectableServiceBase<string> {
  Initialize(ids: string[]): void {
    this.Inited().next(true);
  }
 
  constructor(
    protected http: HttpClient,
    protected cookiService: CookieService
    ) { 
    super();
  }

  public GetMonitoringData(equipmentId:string){
    return this.http.get<any>(`${environment.apiUrl}/support/monitoring?&id=${equipmentId}&apikey=${environment.apiKey}`, this.getHttpOptions());
    
  }

}
