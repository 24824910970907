import { BatteryLevel } from '../battery/battery.component';
import { SensorPowerSupply } from '../sensors.type';
import { IPowerSupply } from './powersupply.interface';

export class PowerSupply implements IPowerSupply{
    private powerSupply:IPowerSupply = new NullPowerSupply();
    public constructor(protected powerSupplyType:SensorPowerSupply){
        switch(this.powerSupplyType){
            case SensorPowerSupply.BATTERY:
                console.log("POWER: BATTERY")
                this.powerSupply = new BatteryPowerSupply();
                break;    
            case SensorPowerSupply.WIRED:
                console.log("POWER: WIRED")
                this.powerSupply = new WiredPowerSupply();
                break;
        }
    }
    public CalculateLevel(u: number): BatteryLevel {
        if(this.powerSupply == null){
            console.log("POWER: BATTERY NULL")
            return BatteryLevel.LNULL;
        }
        return this.powerSupply.CalculateLevel(u);
    }
}
class WiredPowerSupply implements IPowerSupply {
    public CalculateLevel(u: number): BatteryLevel {
        return BatteryLevel.LWIRED
    }

}
class BatteryPowerSupply implements IPowerSupply {
    public CalculateLevel(u: number): BatteryLevel {
        console.log(`POWER: BATTERY ${u}`)
        if(u > 40){
            console.log("POWER: BATTERY L100")
            return BatteryLevel.L100;
        }
            
        if(u > 38){
            console.log("POWER: BATTERY L75")
            return BatteryLevel.L75
        }
            
        if(u > 36){
            console.log("POWER: BATTERY L50")
            return BatteryLevel.L50
        }
            
        if(u > 35){
            console.log("POWER: BATTERY L25")
            return BatteryLevel.L25
        }
            
        if(u > 34){
            console.log("POWER: BATTERY LWARRNIG")
            return BatteryLevel.LWARRNING
        }
            
        if(u > 23){
            console.log("POWER: BATTERY LALERT")
            return BatteryLevel.LALERT        
        }
        console.log("POWER: BATTERY EMPTY")    
        return BatteryLevel.LEMPTY
    }

}

class NullPowerSupply implements IPowerSupply {
    public CalculateLevel(u: number): BatteryLevel {
        return BatteryLevel.LNULL;
    }
    
}
