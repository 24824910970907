import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupedSensorComponent } from './groupedsensor.component';
import { SensorErrorModule } from '@dmonitor/sensors/sensorerror/sensorerror.module';
import { SensorLoadingModule } from '@dmonitor/sensors/sensorloading/sensorloading.module';
import { ExpandableModule } from '../expandable/expandable.module';
import { TranslateModule } from '@ngx-translate/core';
import { ResponsiveModule } from 'ngx-responsive';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BatteryModule } from '../battery/battery.module';
import { GsmSignalIndicatorModule } from '../gsmsignalindicator/gsmsignalindicator.module';
import { NgxLiquidGaugeModule } from 'ngx-liquid-gauge';
import { TankModule } from '@dmonitor/sensors/tank/tank.module';
import { ThermometerModule } from '@dmonitor/sensors/thermometer/thermometer.module';
import { StatusindicatorModule } from '@dmonitor/sensors/statusindicator/statusindicator.module';
import { HygrometerModule } from '@dmonitor/sensors/hygrometer/hygrometer.module';
import { SensorListComponent } from '@dmonitor/sensors/groupedsensor/sensorlist/sensorlist.component';
import { IntegratorModule } from '@dmonitor/sensors/integrator/integrator.module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      SensorErrorModule,
      BatteryModule,
      GsmSignalIndicatorModule,
      SensorLoadingModule,
      NgxLiquidGaugeModule,
      TranslateModule.forChild(),
      ResponsiveModule.forRoot(),
      NgxChartsModule,
      ExpandableModule,
      TankModule,
      ThermometerModule,
      StatusindicatorModule,
      HygrometerModule,
      IntegratorModule
    ],
    exports: [GroupedSensorComponent],
    declarations: [
      GroupedSensorComponent,
      SensorListComponent
    ]
  })
  export class GroupedSensorModule {}
