import { InitableInterface } from './initable.interface';
import { SelectableInterface } from './selectable.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApiService } from './api.service';

export abstract class SelectableServiceBase<T> extends ApiService implements InitableInterface,SelectableInterface<T>{
    abstract Initialize(ids:Array<string>): void 
    protected selectedId :T;
    private $isInited = new BehaviorSubject<boolean>(false);
    private $selectionChanged = new BehaviorSubject<T>(null);
  
    public IsInited(): Observable<boolean> {
        return this.$isInited.pipe(filter(v => v == true));
        
    }

    public Inited(): BehaviorSubject<boolean> {
        return this.$isInited;
    }

    public GetSelected(): T {
        return this.selectedId;
    }

    public SetSelected(id: T): void {
        this.selectedId = id;
        this.$selectionChanged.next(this.selectedId);
        
    }

    public IsSelected(): boolean {
        const s:any = this.selectedId;
        return (s != null && s != '' && s != "0" && s != 0);
    }

    public SelectionChanged(): BehaviorSubject<T> {
        return this.$selectionChanged;
    }


}