import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController, LoadingController} from '@ionic/angular';
import { Company } from 'src/app/shared/dto/company.dto';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserService,CompanyService, DataService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.pug',
  styleUrls: ['./company-dialog.component.sass'],
})
export class CompanyDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.company_dialog';
  @Input() isNew: boolean = false;
  private company:Company;
  public title = '';
  public validation_messages = [];
  public companyname = new FormControl('', 
    Validators.compose([
      Validators.required,   
      Validators.minLength(3) 
    ])
  );

  public companyform = new FormGroup({
    companyname: this.companyname,
  });

  constructor(
    public modalController: ModalController, 
    loadingController: LoadingController,
    translateService: TranslateService,
    private dataService: DataService,
    toastController: ToastController
    ) {
      super(toastController,loadingController,translateService);
     }

 
  public ngOnInit() {
    this.validation_messages = [
      { type: 'required', message: this.translate("msg_error_required") },
      { type: 'minlength', message: this.translate("msg_error_min_character") },  
    ];

    this.dismissLoading(1000);
    if(this.isNew){
      this.title = this.translate("title_add");
      return;
    }
    this.title = this.translate("title_modify");
    
    this.company = this.dataService.GetCompany(this.dataService.CompanyService.GetSelected());
    this.companyname.setValue(this.company.name);
  }

  public dismiss(success:Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(data){
    this.presentLoading();
    if(this.isNew){
      this.dataService.CompanyService.add(this.companyname.value).subscribe(
        (res:any) => {
          if(res.result.success == true){
            this.showSuccessToast(this.translate("msg_success"));
            this.dismiss(true);
          }else{
            this.showFailedToast(this.translate("msg_save_error"));
            this.dismiss(true);
          }
        },
        (error:any) => {
          debugger
          this.showErrorToast(this.translate("msg_save_error"),error);
          this.dismissLoading();
        },()=>{          
          
        }
      );

    } else {
      this.dataService.CompanyService.edit(this.company.id, this.companyname.value).subscribe(
        (res:any) => {
          if(res.result.success == true){           
            this.showSuccessToast(this.translate("msg_success_modify"));
            this.dismiss(true);
          }else{
            this.showFailedToast(this.translate("msg_save_error_modify"));
            this.dismiss(true);
          }
        },
        (error:any) => {
          this.showErrorToast(this.translate("msg_save_error_modify"),error);
          this.dismissLoading();
        }
      );
  
    }
      

  }

}
