import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import * as gauges from 'canvas-gauges';
import {ThermometerData, GaugeData, ThermometerSetting} from '@dmonitor/shared/response/sensor/thermometer';

@Component({
  selector: 'thermometer-lineargauge',
  templateUrl: 'lineargauge.component.pug',
  styleUrls: ['lineargauge.component.sass'],
})
export class LineargaugeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() Data: ThermometerData;
  @Input() GaugeData: GaugeData;
  @Input() Settings: ThermometerSetting;
  @Input() GsmSignalStregth:number;
  @Input() BatteryLevel = BatteryLevel.LNULL;
  public SensorPowerSupply = SensorPowerSupply;

  @ViewChild("canvasgauge", { read: ElementRef }) canvasEl: ElementRef;
  public IsError = false;
  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }
  public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }


  private divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }


  private createRadialGauge() {
    var gaugesLib = gauges;
    var radialGauge = new gaugesLib.LinearGauge({
      width: 440,
      height: 120,
      units: "°C",
      renderTo: this.canvasEl.nativeElement,
      //units: "°C",
      title: "Hőmérséklet",
      minValue: 0,
      maxValue: Number(this.GaugeData.MaxUnit),
      majorTicks: JSON.parse(this.GaugeData.Tricks),
      minorTicks: 5,
      strokeTicks: true,
      ticksWidth: 15,
      ticksWidthMinor: 7.5,
      highlights: JSON.parse(this.GaugeData.DataHighlights),
      colorMajorTicks: "#ffe66a",
      colorMinorTicks: "#ffe66a",
      colorTitle: "#000000",
      colorUnits: "#131313",
      colorNumbers: "#070707",
      colorPlate: "#ffffff",
      colorPlateEnd: "#ffffff",
      borderShadowWidth: 0,
      borders: false,
      borderRadius: 10,
      needleType: "arrow",
      needleWidth: 3,
      animationDuration: 1500,
      animationRule: "linear",
      colorNeedle: "#222",
      colorNeedleEnd: "",
      colorBarProgress: "#f08680",
      colorBar: "#dcdcdc",
      barStroke: 0,
      barWidth: 8,
      barBeginCircle: false,
      value: this.Data != null ? this.Data.t : 0,

    }).draw();
  }

}
