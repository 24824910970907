import { Component, Input, OnInit } from '@angular/core';
import { IntegratorParameters, IntegratorData } from '@dmonitor/shared/response/sensor/integrator';
import { Equipment } from '@dmonitor/shared/dto';
import { BaseComponent } from '@dmonitor/shared/index';
import { MonitoringService } from '@dmonitor/services/monitoring.service';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PowerSupply } from '@dmonitor/sensors/powersupply/powersupply';
import { SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { SignalStrength } from '@dmonitor/sensors/gsmsignalindicator/gsmsignalindicator.component';

@Component({
  selector: 'integrator-monitoringview',
  templateUrl: 'monitoring.component.pug',
  styleUrls: ['monitoring.component.sass'],
})
export class MonitoringComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.equipment.integrator.monitoring';
  @Input() EquipmentId: string;
  @Input() StartExpanded: boolean = true;
  public Data:any;
  public Loading = true;
  public BatteryLevel = BatteryLevel.LNULL;
  public GsmSignalStregth:number = 0;
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,    
    translateService: TranslateService,
    private monitoringService:MonitoringService
  ){
    super(toastController,loadingController,translateService)
  }
  public expanded = false;

  public ngOnInit(): void {
    console.log(`EXPAND ${this.StartExpanded}`);
    this.expanded = this.StartExpanded;
    this.monitoringService.GetMonitoringData(this.EquipmentId).subscribe(res =>{
      this.Data = res.result;
      const powerSupply = new PowerSupply(SensorPowerSupply.BATTERY)
      this.BatteryLevel = powerSupply.CalculateLevel(Number(this.Data.u));
      this.configureGsmSignalStregth();
       
      this.Loading = false;
    });
  }

  public expandItem(item): void {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  public parseFloat(num: string) {
    return parseFloat(num).toPrecision(6);
  }

  public divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }

  private getDeviceType(type: number): string {
    if (type == 0) {
      return "0...20 mA";
    }
    if (type == 1) {
      return "4...20 mA";
    }
    if (type == 2) {
      return "Frekvencia";
    }

    return "-";
  }

  private getComPeriod(type:number):string{
    if (type == 0) {
      return "2 min";
    }
    if (type == 1) {
      return "5 min";
    }
    if (type == 2) {
      return "10 min";
    }
    if (type == 3) {
      return "30 min";
    }
    if (type == 4) {
      return "60 min";
    }
    if (type == 5) {
      return "3 h";
    }
    if (type == 6) {
      return "6 h";
    }
    if (type == 7) {
      return "12 h";
    }
    return "-"
  }

  private configureGsmSignalStregth(){
    const st = Number(this.Data.st);
    if(st >= 20){
        this.GsmSignalStregth = SignalStrength.Excellent;
        return;
    }
    if(st >= 15){
        this.GsmSignalStregth = SignalStrength.Good;
        return;
    }
    if(st >= 10){
        this.GsmSignalStregth = SignalStrength.OK;
        return;
    }
    if(st < 2){
        this.GsmSignalStregth = SignalStrength.NoGsm;
        return;

    }
    this.GsmSignalStregth = SignalStrength.Marginal;

}

}