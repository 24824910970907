import { Component, ElementRef, OnInit } from '@angular/core';

import { Platform, LoadingController, ToastController, MenuController, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { HomeService, DataService } from '@dmonitor/services/index';
import { HttpErrorResponse } from '@angular/common/http';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent, UrlHelper } from './shared';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LogsDialogComponent } from './dialogs/logsdialog/logs-dialog.component';

const PARAM_API_URL = "apiUrl";
const PARAM_API_KEY = "apiKey";
const PARAM_TOKEN = "sessionId";
const PARAM_THEME = "theme";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.pug',
  styleUrls: ['app.component.sass']
})
export class AppComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = '';
  public isAuthError: boolean = false;
  public IsLoggedIn = false;
  public IsInitialized = false;
  public baseUrl: string = "";
  public user: string;
  public loadingUser: boolean = true;
  public loadingSites: boolean = true;
  public loadingCompanies: boolean = true;
  public loadingCounties: boolean = true;
  public loadingEquipments: boolean = true;
  public ErrorMessage: string = '';
  public isError: boolean = false;
  public appVersion = environment.appVersion;

  public pages = [
    {
      title: 'main_menu.home',
      click: 'this.navigateHome()',
      icon: 'home'
    },
    
    {
      title: 'main_menu.change_password',
      url: '/changepassword',
      icon: 'keypad'
    },
    {
      title: 'main_menu.about',
      url: '/about',
      icon: 'help-circle-outline',
    },
    {
      title: 'main_menu.logout',
      icon: 'log-out',
      click: 'this.logout()'
    }
  ];
  public appPages = [];
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService,
    private menuCtrl: MenuController,
    private router: Router,
    private cookiService: CookieService,
    private modalController: ModalController,
    private platform: Platform,
    private dataService: DataService,
    private elementRef: ElementRef,
    private homeService: HomeService
  ) {
    super(toastController, loadingController, translateService);
    this.initializeParameters();
    translateService.setDefaultLang('hu');
    this.baseUrl = window.location.origin;

  }
  public ngOnInit(): void {
    this.menuCtrl.enable(false);
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd && event.url === '/login') {
        this.menuCtrl.enable(false);
      }
    });
    this.platform.ready().then(() => {
      this.translateService.get('dialog_loading_msg').subscribe(msg => {
        this.dataService.Error.subscribe(err => {
          this.isError = true;
          this.ErrorMessage = err.message;
          //debugger;
        });
        this.dataService.IsInited.subscribe(() => {
          this.dismissLoading();
          console.log("Data Service Inited");
          this.calculateAndNavigateHome();
        });
        this.dataService.AuthService.$IsLoggedIn.subscribe((isLoggedIn) => {
          if (isLoggedIn == false) {
            this.IsLoggedIn = false;
            return;
          }
          if (this.IsLoggedIn == true) {
            return;
          }
          console.log("App initialize");
          this.IsLoggedIn = true;

          setTimeout(() => {
            this.presentLoading(msg);
            this.menuCtrl.enable(true);
          }, 100);
          this._initializeServices();

        })
      });
    });

  }


  private calculateAndNavigateHome(): void {
    //debugger;
    this.homeService.IsInited.subscribe(inited => {
      if (inited == false) {
        return;
      }
      this.homeService.navigateHome();
    });
    this.homeService.calculateHome();
  }

  private initializeParameters(): void {
    const apiUrl = this.elementRef.nativeElement.getAttribute(PARAM_API_URL);
    if (apiUrl != null) {
      environment.apiUrl = apiUrl;
    }
    const apiKey = this.elementRef.nativeElement.getAttribute(PARAM_API_KEY);
    if (apiKey != null) {
      environment.apiKey = apiKey;
    }
    const token = this.cookiService.get('token');
    if (token == "") {
      const token = this.elementRef.nativeElement.getAttribute(PARAM_TOKEN);
      if (token != null) {
        environment.token = token;
      }
    } else {
      environment.token = token;
    }
    const theme = this.elementRef.nativeElement.getAttribute(PARAM_THEME);
    if (theme != null) {
      environment.theme = theme;
    }

  }

  private _initializeServices(): void {
    this.dataService.UserService.whoami().subscribe(res => {
      const response = res.result;
      this.loadingUser = false;
      if (response.userData.email == "") {
        this.isAuthError = true;
        this.dismissLoading();

        return;
      }

      this.isError = false;
      this.user = response.userData.email;
      this.isAuthError = false;
      this.dataService.LoggedInUser = response.userData;
      
     

      setTimeout(() => {
        this.dataService.UserService.Inited().subscribe(inited =>{
          if(inited){
            this.createMenu();
          }
        })
        this.dataService.UserService.setUserData(res.result.userData);        
        
        this.dataService.InitializeFromData(res.result);

        this.isError = false;

      }, 1000);
    }, (err: HttpErrorResponse) => {
      this.dismissLoading();
      if (err.status == 401) {
        this.IsLoggedIn = false;
        this.homeService.navigateLogin();
        return;
      }
      console.log(err);
      this.SetError(err.message);
      this.loadingUser = false;
    });

  }

  private createMenu():void{
    this.appPages = [];
    this.pages.forEach(e =>{
      this.appPages.push(e);
    });
    const user = this.dataService.LoggedInUser;
    if(user.viewSettings.enableHierarchyView){
      this._addMenuItemsForHierarchyView();
    }
    if (user.superadmin == "1") {
      this._addMenuItemsForSuperadmin();
    }
  }

  private SetError(msg: string): void {
    this.isError = true;
    this.ErrorMessage = msg;
    this.dismissLoading();

  }
  private _addMenuItemsForHierarchyView() {
    
    /*const found = this.appPages.find(m => m.title == 'main_menu.companies');
    if(found){
      return;
    }*/
    this.appPages.push({
      title: 'main_menu.companies',
      url: '',
      click: 'this.companies()',
      icon: 'list'
    });
  }

  private _addMenuItemsForSuperadmin() {
    /*
    const found = this.appPages.find(m => m.title == 'main_menu.user_management');
    if(found){
      return;
    }
    */
    this.appPages.push({
      title: 'main_menu.user_management',
      url: '/user',
      icon: 'people'
    });

    this.appPages.push({
      title: 'main_menu.superadmin',
      click: 'this.superadmin()',
      icon: 'construct'
    });
    this.appPages.push({
      title: 'main_menu.systemlogs',
      click: 'this.systemlogs()',
      icon: 'folder'
    });
    this.appPages.push({
      title: 'main_menu.consolelogs',
      click: 'this.consolelogs()',
      icon: 'folder'
    });
  }


  public menuClick(functionName) {
    eval(functionName);
  }

  public navigateHome() {
    this.dataService.ReloadUserRights().subscribe(inited => {
      if(inited == false){
        return;
      }
      this.homeService.calculateHome();
      this.homeService.navigateHome();
    });
  }

  public logout() {
    this.menuCtrl.enable(false);
    this.IsLoggedIn = false;
    this.dataService.AuthService.logout().subscribe((res) => {
      this.router.navigate([UrlHelper.URL_LOGIN]);

      /*
      if (res.result.loginurl != null) {
        window.location.href = res.result.loginurl;
      }
      */
    });
  }

  public superadmin(): void {
    this.dataService.$IsSuperadminMode.next(true);
    this.router.navigate([`/${UrlHelper.URL_COMPANIES}`]);
  }

  public companies():void{
    this.dataService.$IsSuperadminMode.next(false);
    this.router.navigate([`/${UrlHelper.URL_COMPANIES}`]);

  }

  public home(): void {
    this.dataService.$IsSuperadminMode.next(false);
    this.homeService.navigateHome();
  }

  public async systemlogs(){
    this.presentLoading();

    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: LogsDialogComponent,
        componentProps: {
          type:1,
          equipmentId: null
        }
      });
    modal.onDidDismiss().then((detail: OverlayEventDetail) => {

      if (detail !== null && detail.data == true) {
        this.dataService.LoadSites();
      }
      this.dismissLoading();
    });

    await modal.present();
  }
  public async consolelogs(){
    this.presentLoading();

    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: LogsDialogComponent,
        componentProps: {
          type:3,
          equipmentId: null
        }
      });
    modal.onDidDismiss().then((detail: OverlayEventDetail) => {

      if (detail !== null && detail.data == true) {
        this.dataService.LoadSites();
      }
      this.dismissLoading();
    });

    await modal.present();
  }
}
