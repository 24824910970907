import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertResponse } from "@dmonitor/shared/response/alert.response";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
  })
  export class AlertService extends ApiService{
    constructor(
        protected http: HttpClient,
        protected cookiService: CookieService
        ) { 
        super();
      }
      public getAllAlerts():Observable<AlertResponse>{
        return this.http.get<AlertResponse>(`${environment.apiUrl}/alert/all?apikey=${environment.apiKey}`, this.getHttpOptions());
      }
      public deleteAlert(id:string){
        const postData = JSON.stringify({
          id: id
        });
        return this.http.post<Object>(`${environment.apiUrl}/alert/delete?apikey=${environment.apiKey}`,postData, this.getHttpOptions());
      }
  }