import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Site, Equipment } from '@dmonitor/shared/dto/index';
import { IntegratorResponse, IntegratorDailyData } from '@dmonitor/shared/response/sensor/integrator';
import { EquipmentsListData, Response } from '@dmonitor/shared/response';
import { filter } from 'rxjs/operators';
import { UserService } from './user.service';
import { SelectableServiceBase } from './selectable.service.base';
import { CookieService } from 'ngx-cookie-service';
import {ThermometerDailyData, ThermometerResponse} from '@dmonitor/shared/response/sensor/thermometer';
import {HygrometerDailyData, HygrometerResponse} from '@dmonitor/shared/response/sensor/hygrometer';
import {StatusindicatorDailyData, StatusindicatorResponse} from '@dmonitor/shared/response/sensor/statusindicator';
import {TankDailyData, TankResponse} from '@dmonitor/shared/response/sensor/tank';
import {GroupedSensorResponse} from '@dmonitor/shared/response/sensor/groupedsensor';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends SelectableServiceBase<string> {
  Initialize(ids: string[]): void {
    throw new Error('Method not implemented.');
  }

  url = environment.apiUrl;
  apiKey = '';
  private isInitedSubject = new BehaviorSubject(false);
  public ErrorSubject = new BehaviorSubject<HttpErrorResponse>(null);
  public equipments: Array<Equipment> = [];
  constructor(
    protected http: HttpClient,
    protected cookiService: CookieService,
    private userService: UserService
  ) {
    super();
  }
  public IsInited(): Observable<boolean> {
    return this.isInitedSubject.pipe(filter(v => v == true));
  }
  public Inited(): BehaviorSubject<boolean> {
    return this.isInitedSubject;
  }


  public getAllEquipments(siteIds: Array<string>, adData = []) {
    return this.http.get<any>(
        `${environment.apiUrl}/equipment?sid=${JSON.stringify(siteIds)}&ad=${JSON.stringify(adData)}&apikey=${environment.apiKey}`,
        this.getHttpOptions()
    );
  }

  public getIntegratorData(integratorId: string): Observable<Response<IntegratorResponse>> {
    return this.http.get<any>(`${environment.apiUrl}/integrator/data/${integratorId}`);
  }

  public getThermometerData(thermometerId: string): Observable<Response<ThermometerResponse>> {
    return this.http.get<any>(`${environment.apiUrl}/thermometer/data/${thermometerId}`);
  }
  public getHygrometerData(hygrometerId: string): Observable<Response<HygrometerResponse>> {
    return this.http.get<any>(`${environment.apiUrl}/hygrometer/data/${hygrometerId}`);
  }
  public getStatusindicatorData(statusindicatorId: string): Observable<Response<StatusindicatorResponse>> {
    console.warn(this.http.get<any>(`${environment.apiUrl}/status_indicator/data/${statusindicatorId}`));
    return this.http.get<any>(`${environment.apiUrl}/status_indicator/data/${statusindicatorId}`);
  }
  public getTankData(tankId: string): Observable<Response<TankResponse>> {
    return this.http.get<any>(`${environment.apiUrl}/tank/data/${tankId}`);
  }
  public getGroupedSensorData(groupedSensorId: string): Observable<Response<GroupedSensorResponse>> {
    return this.http.get<any>(`${environment.apiUrl}/grouped_sensor/data/${groupedSensorId}`);
  }
  public GetAllEquipmentsForUser(): Observable<Response<EquipmentsListData[]>> {
    if (this.getHttpOptions().headers.get('X-Auth-Token').length > 0) {
      return this.http.get<any>(`${environment.apiUrl}/equipment/all`, this.getHttpOptions());
    }

  }
  public getIntegratorDailyStatisticData(integratorId: string, date: string): Observable<Response<IntegratorDailyData[]>> {
    return this.http.get<any>(`${environment.apiUrl}/integrator/data/${integratorId}/statistics/daily/${date}`);
  }
  public getThermometerDailyStatisticData(thermometerId: string, date: string): Observable<Response<ThermometerDailyData[]>> {
    return this.http.get<any>(`${environment.apiUrl}/thermometer/data/${thermometerId}/statistics/daily/${date}`);
  }
  public getHygrometerDailyStatisticData(hygrometerId: string, date: string): Observable<Response<HygrometerDailyData[]>> {
    return this.http.get<any>(`${environment.apiUrl}/hygrometer/data/${hygrometerId}/statistics/daily/${date}`);
  }
  public getStatusindicatorDailyStatisticData(statusindicatorId: string, date: string): Observable<Response<StatusindicatorDailyData[]>> {
    return this.http.get<any>(`${environment.apiUrl}/status_indicator/data/${statusindicatorId}/statistics/daily/${date}`);
  }
  public getTankDailyStatisticData(tankId: string, date: string): Observable<Response<TankDailyData[]>> {
    return this.http.get<any>(`${environment.apiUrl}/tank/data/${tankId}/statistics/daily/${date}`);
  }
  /*
    public Initialize(sites:Array<Site>, adData = []){
  
      return this.getAllEquipments(this._createSiteIdsArray(sites), []).subscribe(
        (res:DefaultResponse) => {    
          let equipments = res.result;
          let filteredEquipments = [];
          equipments.forEach((e:Equipment) => {
            this.userService.getEquipmentPrivilege().forEach(
              (ep:EquipmentPrivilege) => {
                if(e.id == ep.equipment_id.toString() && ep.privilege_id.toString() != "0"){
                  filteredEquipments.push(e);
                }
              }
            );
          });
          this.equipments = filteredEquipments;
          this.ErrorSubject.next(null);
          this.isInitedSubject.next(true);
        },
        (err) => {
          console.log(err)
          this.isInitedSubject.next(true);
          this.ErrorSubject.next(err);
        }
      );
    }
  */
  private _createSiteIdsArray(sites: Array<Site>) {
    var siteIds: Array<string> = [];
    sites.forEach(function (v) {
      siteIds.push(v.id);
    });
    return siteIds;
  }

  public getEquipmentsBySelectedSite(siteId) {
    let filtered = [];
    return this.equipments.filter(eq => {
      return eq.site_id == siteId;
    });
  }


  public add(equipment: Equipment) {
    //debugger;

    return this.http.post<object>(`${environment.apiUrl}/equipment/add?apikey=${environment.apiKey}`, equipment, this.getHttpOptions());
  }

  public edit(equipment: Equipment) {
    return this.http.post<object>(`${environment.apiUrl}/equipment/edit?apikey=${environment.apiKey}`, equipment, this.getHttpOptions());
  }

  public delete(equipmentId: string) {
    const postData = JSON.stringify({
      id: equipmentId
    });

    return this.http.post<object>(`${environment.apiUrl}/equipment/delete?apikey=${environment.apiKey}`, postData, this.getHttpOptions());
  }

}
