import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController, NavParams, LoadingController} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService, UserService, DataService } from '@dmonitor/services/index';
import { BaseComponent } from '@dmonitor/shared/index';
@Component({
  selector: 'app-rightedit-dialog',
  templateUrl: './rightedit-dialog.component.pug',
  styleUrls: ['./rightedit-dialog.component.sass'],
})
export class RighteditDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.user_rights_edit_dialog';
  private uid: string;
  private itemid: string;
  private aid: string;
  private priv: string;
  constructor(
    public modalController: ModalController, 
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService,
    private navParams: NavParams,
    private dataService: DataService,
  ) {
    super(toastController,loadingController,translateService);
   }


  ngOnInit() {
    this.uid = this.navParams.get('uid');
    this.itemid = this.navParams.get('itemid');
    this.aid = this.navParams.get('aid');
    this.priv = this.navParams.get('priv');
    this.dismissLoading(1000);
  }

  public dismiss(success:Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(data){
    this.presentLoading();
    this.dataService.UserService.changePrivilege(this.uid, this.itemid, this.aid, data.privilege).subscribe(
      (res: any) => {
        
        if(res.result.success == true){
          this.dataService.ReloadUserRights();
          this.showSuccessToast(this.translate("msg_success"));
      
          this.dismiss(true);
        
        }else{
          this.showFailedToast(this.translate('msg_save_error'));
        }
      },
      err => {
        if(err.error != null) {
          this.showErrorToast(this.translate('msg_save_error'),err);
        }else{
          this.showErrorToast(this.translate('msg_save_error'),err);
        }
        
      },
      ()=>{
        this.dismissLoading();
      }
    );
  }

}
