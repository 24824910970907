import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CompaniesPageRoutingModule } from './companies-routing.module';

import { CompaniesPage } from './companies.page';
import { CompanyDialogModule } from '@dmonitor/dialogs/companydialog/company-dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { MoreButtonComponent } from './more-button/more-button.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CompaniesPageRoutingModule,
    TranslateModule.forChild(),
    CompanyDialogModule
  ],
  declarations: [CompaniesPage,MoreButtonComponent],
  entryComponents: [MoreButtonComponent]
})
export class CompaniesPageModule {}
