import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { UrlHelper } from './shared';

const routes: Routes = [
  {
    path: '',
    redirectTo: `${UrlHelper.URL_LOGIN}`,
    //loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: `${UrlHelper.URL_LOGIN}`,
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: `${UrlHelper.URL_COMPANIES}`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesPageModule)
  },
  {
    path: `${UrlHelper.URL_COUNTIES}/:companyId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./counties/counties.module').then(m => m.CountiesPageModule)
  },
  {
    path: `${UrlHelper.URL_SITES}/:companyId/:countyId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./sites/sites.module').then(m => m.SitesPageModule)
  },
  {
    path: 'about',
    canActivate: [AuthGuard],
    loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'changepassword',
    canActivate: [AuthGuard],
    loadChildren: () => import('./changepassword/changepassword.module').then(m => m.ChangepasswordPageModule)
  },
  {
    path: `${UrlHelper.URL_EQUIPMENTS}/:companyId/:countyId/:siteId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./equipments/equipments.module').then(m => m.EquipmentsPageModule)
  },
  {
    path: `${UrlHelper.URL_EQUIPMENT}/:companyId/:countyId/:siteId/:equipmentId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule)
  },
  {
    path: `${UrlHelper.URL_EQUIPMENTS_LIST}`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./equipmentslist/equipments.list.module').then(m => m.EquipmentsListPageModule)
  },
  {
    path: `${UrlHelper.URL_USER}`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserPageModule)
  },
  {
    path: `${UrlHelper.URL_USER}/:userId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserPageModule)
  },
  {
    path: `${UrlHelper.URL_RIGHTS}/:userId/:areaId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./rights/rights.module').then(m => m.RightsPageModule)
  },
  {
    path: `${UrlHelper.URL_RIGHTS}/:userId/:areaId/:companyId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./rights/rights.module').then(m => m.RightsPageModule)
  },
  {
    path: `${UrlHelper.URL_RIGHTS}/:userId/:areaId/:companyId/:countyId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./rights/rights.module').then(m => m.RightsPageModule)
  },
  {
    path: `${UrlHelper.URL_RIGHTS}/:userId/:areaId/:companyId/:countyId/:siteId`,
    canActivate: [AuthGuard],
    loadChildren: () => import('./rights/rights.module').then(m => m.RightsPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
