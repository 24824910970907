import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EquipmentDialogComponent } from './equipment-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { IntegratorModule } from '@dmonitor/sensors/integrator/integrator.module';
import {ThermometerModule} from '@dmonitor/sensors/thermometer';
import {HygrometerModule} from '@dmonitor/sensors/hygrometer';
import {StatusindicatorModule} from '@dmonitor/sensors/statusindicator/statusindicator.module';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IntegratorModule,
    ThermometerModule,
    HygrometerModule,
    TranslateModule.forChild(),
    IonicModule,
    StatusindicatorModule,
  ],
  declarations: [EquipmentDialogComponent],
  entryComponents: [EquipmentDialogComponent]
})
export class EquipmentDialogModule {}
