import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'battery',
    templateUrl: 'battery.component.pug',
    styleUrls: [ 'battery.component.sass'],
  })
  export class BatteryComponent implements OnInit{
    public ngOnInit(): void {
    }
    @Input() public level:BatteryLevel = null;
    BatteryLevel = BatteryLevel;
  }
  export enum BatteryLevel{
      L100 = 1,
      L75 = 2,
      L50 = 3,
      L25 = 4,
      LWARRNING = 5,
      LALERT =  6,
      LEMPTY = 7,
      LNULL = 8,
      LWIRED = 9
  }