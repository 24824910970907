import { Component, Input, OnInit } from '@angular/core';
import { DailyData, IntegratorSetting } from '@dmonitor/shared/response/sensor/integrator';
import { BaseComponent } from '@dmonitor/shared/index';
import { ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeviceColors, DeviceType } from '@dmonitor/sensors/sensors.type';

@Component({
  selector: 'integrator-dailydatagraph',
  templateUrl: 'dailydatagraph.component.pug',
  styleUrls: ['dailydatagraph.component.sass'],
})
export class DailydataGraphComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = "page.equipment.integrator.dailydata.graph";
  @Input() DailyData: DailyData[] = [];
  @Input() Settings: IntegratorSetting;
  showXAxis = true;
  showYAxis = true;
  xAxisLabel = '';
  yAxisLabel = '';
  graphData = null;
  colorScheme = {
    domain: [DeviceColors.COLD_GRAPH_COLOR]
  };
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService) {
    super(toastController, loadingController, translateService);
  }
  public ngOnInit(): void {
    this.xAxisLabel = this.translate('xlabel');
    this.yAxisLabel = this.translate('ylabel');
    if(this.Settings.device_type == DeviceType.HOT_WATER){
      this.colorScheme = {
        domain: [DeviceColors.HOT_GRAPH_COLOR]
      };    
    }
    setTimeout(() => {
      this.graphData = this.DailyData.reverse().map(d => ({
        name: d.date.replace("-",".").replace("-","."),
        value: d.vm == null ? "0" : d.vm
      }));

    }, 100);
  }
}