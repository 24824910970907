import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController, LoadingController} from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Site } from '@dmonitor/shared/dto';
import { SiteService, DataService,CountyService } from '@dmonitor/services/index';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@dmonitor/shared/index';

@Component({
  selector: 'app-site-dialog',
  templateUrl: './site-dialog.component.pug',
  styleUrls: ['./site-dialog.component.sass'],
})
export class SiteDialogComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.site_dialog';
  @Input() isNew: boolean = false;
  @Input() companyId: string;
  @Input() countyId: string;
  private site:Site;
  public title = '';
  public validation_messages = [];
  
  public sitename = new FormControl('', 
    Validators.compose([
      Validators.required,   
      Validators.minLength(3) 
    ])
  );

  public form = new FormGroup({
    sitename: this.sitename,
  });

  constructor(
    toastController: ToastController,
    loadingController:LoadingController,
    translateService: TranslateService,
    public modalController: ModalController, 
    private dataService:DataService,
    
    ) {
        super(toastController,loadingController,translateService);
     }
  public ngOnInit() {
    this.validation_messages = [
      { type: 'required', message: this.translate('msg_error_required') },
      { type: 'minlength', message: this.translate('msg_error_min_character') },  
    ];
    this.dismissLoading();
    if(this.isNew){
      this.title = this.translate('title_add');
      return;
    }
    this.title = this.translate('title_modify');
    this.site = this.dataService.GetSelectedSite();
    this.sitename.setValue(this.site.name);
  }

  public dismiss(success:Boolean) {
    this.modalController.dismiss(success);
  }

  public onClickSubmit(data){
    this.presentLoading();
    if(this.isNew){
      this.dataService.SiteService.add(this.companyId,this.countyId,this.sitename.value).subscribe(
        (res:any) => {
          this.dismissLoading();
          if(res.result.success == true){
            this.showSuccessToast(this.translate('msg_success'));
            this.dismiss(true);
          }else{
            this.showFailedToast(this.translate('msg_save_error'));
            this.dismiss(true);
          }
        },
        (error:any) => {
          this.dismissLoading();
          this.showErrorToast(this.translate('msg_save_error'),error);
          
        }
      );

    } else {
      this.dataService.SiteService.edit(this.site.id, this.sitename.value).subscribe(
        (res:any) => {
          this.dismissLoading();
          if(res.result.success == true){
            this.showSuccessToast(this.translate('msg_success_modify'));
            this.dismiss(true);
          }else{
            this.showFailedToast(this.translate('msg_save_error_modify'));
            this.dismiss(true);
          }
        },
        (error:any) => {
          this.dismissLoading();
          this.showErrorToast(this.translate('msg_save_error_modify'),error);
        }
      );
  
    }
      

  }


}
