import { Component, Input, OnInit } from '@angular/core';
import { DailyData } from '@dmonitor/shared/response/sensor/integrator';

@Component({
  selector: 'integrator-readings',
  templateUrl: 'readings.component.pug',
  styleUrls: ['readings.component.sass'],
})
export class ReadingsComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.equipment.integrator.monitoring';
  @Input() ReadingsData: DailyData[] = [];
  @Input() StartExpanded: boolean = true;
  public expanded = true;
  public ngOnInit(): void {
  }
  public expandItem(item): void {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }
}