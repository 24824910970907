import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserData } from '../../../shared/dto/userdata.dto';
import { DataService } from '../../../services';
import { ModalController, ToastController, LoadingController, IonRefresher } from '@ionic/angular';
import { UserDialogComponent } from '@dmonitor/dialogs/userdialog/user-dialog.component';
import { OverlayEventDetail } from '@ionic/core';
import { BaseComponent } from '@dmonitor/shared/index';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.pug',
  styleUrls: ['./user-list.component.sass'],
})

export class UserListComponent extends BaseComponent implements OnInit {
  @ViewChild(IonRefresher) public refresher: IonRefresher;
  protected TRANSLATE_KEY: string ='page.users';
  public userList:Array<UserData> = [];
  public url:string = "/user/";
  constructor(
    toastController:ToastController,
    loadingController:LoadingController,
    translateService:TranslateService,
    private dataService: DataService,
    private modalController: ModalController) { 
      super(toastController,loadingController,translateService);
    }

  ngOnInit() {
    this.setUserList();
  }

  public doRefresh(){
    this.dataService.ReloadUserRights().subscribe(res =>{
      this.setUserList();
      setTimeout(() => {
        this.refreshComplete();;
      },5000);  
    });
  }

  private refreshComplete():void{
    if(this.refresher == null){
      return;
    }
    this.refresher.complete();

  }

  private setUserList(){
    this.dataService.UserService.getAllUsers().subscribe((res) => {
      this.userList = res.result;
      this.dismissLoading(500);
      this.refreshComplete();
    },err => {
      this.dismissLoading();
      this.refreshComplete();
      this.showErrorToast(this.translate('error_loading_users'));
    });
  }

  public async openModal() {
    this.presentLoading();
    const modal: HTMLIonModalElement =
       await this.modalController.create({
          component: UserDialogComponent
    });
     
    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
      if (detail !== null && detail.data == true) {        
        this.setUserList();
      }
    });
    
    await modal.present();
  }
}
