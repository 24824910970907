import { Component, Input } from '@angular/core';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import { TankData, TankSetting } from '@dmonitor/shared/response/sensor/tank';

@Component({
  selector: 'tank-display',
  templateUrl: 'display.component.pug',
  styleUrls: ['display.component.sass'],
})
export class DisplayComponent {
  @Input() Data: TankData;
  @Input() Settings: TankSetting;
  @Input() GsmSignalStregth:number;
  @Input() BatteryLevel = BatteryLevel.LNULL;
  public SensorPowerSupply = SensorPowerSupply;
  private divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }

}
