import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { BatteryLevel } from '@dmonitor/sensors/battery/battery.component';
import { SensorPowerSupply } from '@dmonitor/sensors/sensors.type';
import { IntegratorData, GaugeData, IntegratorSetting } from '@dmonitor/shared/response/sensor/integrator';
import * as gauges from 'canvas-gauges';

@Component({
  selector: 'integrator-gauge',
  templateUrl: 'gauge.component.pug',
  styleUrls: ['gauge.component.sass'],
})
export class GaugeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() Data: IntegratorData;
  @Input() GaugeData: GaugeData;
  @Input() Settings: IntegratorSetting;
  @Input() GsmSignalStregth:number;
  @Input() BatteryLevel = BatteryLevel.LNULL;
  public SensorPowerSupply = SensorPowerSupply;

  @ViewChild("canvasgauge", { read: ElementRef }) canvasEl: ElementRef;
  public IsError = false;
  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }
  public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.canvasEl != null) {
      this.createRadialGauge();

    }

  }


  private divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }


  private createRadialGauge() {
    var gaugesLib = gauges;
    console.log('Create gauge');
    var radialGauge = new gaugesLib.RadialGauge({
      width: 386,
      height: 386,
      type: "radial-gauge",
      minValue: "0",
      title: "Q",
      colorTitle: "#000",
      maxValue: this.GaugeData.MaxUnit,
      majorTicks: JSON.parse(this.GaugeData.Tricks),
      units: "m³/h",
      colorNumbers: "#000",
      colorUnits: "#000",
      highlights: JSON.parse(this.GaugeData.DataHighlights),
      animateOnInit: true,
      value: this.Data != null ? this.Data.q : 0,
      renderTo: this.canvasEl.nativeElement,
      valueInt:4,
      valueDec:1,
    }).draw();
  }

}
