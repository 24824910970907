import { Component } from '@angular/core';
import { Company } from '../shared/dto/company.dto';
import { ModalController, NavController, PopoverController, ToastController, LoadingController, MenuController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { filter } from 'rxjs/operators';
import { CompanyDialogComponent } from '@dmonitor/dialogs/companydialog/company-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MoreButtonComponent } from './more-button/more-button.component';
import { UrlHelper, BaseListComponent, ListItem } from '@dmonitor/shared/index';
import { DataService } from '@dmonitor/services/index';
import { CompaniesResponse } from '@dmonitor/shared/response';
import { PrivilegesEnum } from '@dmonitor/shared/dto';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.page.pug',
  styleUrls: ['./companies.page.sass'],
})
export class CompaniesPage extends BaseListComponent {
  
  public BackLink: string = '';
  protected TRANSLATE_KEY: string = 'page.companies';
  private isEditorForCompanyAdd: Boolean = false;
  constructor(
    translateService: TranslateService,
    toastController: ToastController,
    loadingController: LoadingController,
    public modalController: ModalController,
    private menuCtrl: MenuController,
    dataService: DataService,
    private navigateCtr: NavController,
    private popoverController: PopoverController,
    ) { 
      super(toastController,loadingController,translateService,dataService);
      console.log("INIT CONST: Companies");
     
    }

  public ngOnInit():void {
    this.initList();
   
    this.menuCtrl.enable(true);
    console.log("INIT ON INIT: Comapnies");
  }

  protected onListInit(): void {
    this._createListItemsFromCompanies();
  }  

  protected isListReadyForRender(): boolean {
    return true;
  }
  protected onSuperadminListInit():void{
    this.dataService.CompanyService.getAllCompanies().subscribe((resp: CompaniesResponse) => {
      const companies = resp.result;
      companies.forEach((comp: Company) => {
        comp.privilege_id = `${PrivilegesEnum.EDIT}`;
      });
      this.Items = this.createItems(companies,`/${UrlHelper.URL_COUNTIES}`);
      this.dataService.LastListOfCompanies = companies;
      this.refreshComplete();
    });
  }

  protected onSuperadminRefresh():void{
    this.onSuperadminListInit();
  }

  protected setUserRights() {
    this.isEditorForCompanyAdd = this.IsSuperadminMode;
  }

  protected onRefresh() {
    this.dataService.Reload();
  }

  private _createListItemsFromCompanies(): void {
    this.log('_createListItemsFromCompanies');
    this.dataService.CompaniesForUser.pipe(filter(v => v != null)).subscribe(
      companies => {
        this.log('COMPANIES ITEMS INIT');
        this.dataService.LastListOfCompanies = companies;
        this.Items = this.createItems(companies,`/${UrlHelper.URL_COUNTIES}`);
        this.refreshComplete();
      }
    );
  }

  

  public async onItemSelect(item: ListItem) {
    this.setCompany(item);
  }


  public async setCompany(company: any) {
    this.log(`setCompany: ${company.id}`);
    //this.dataService.SetSelectedCompany(company.id);
    this.presentLoading();    
    this.navigateCtr.navigateForward(company.url);
  }

  public async openModal() {
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: CompanyDialogComponent,
        componentProps: {
          isNew: true
        }
      });

    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
      if (detail !== null && detail.data == true) {
      }
      this.dataService.Reload();
      this.dismissLoading();
    });
    this.presentLoadingWith(1000, () => {
      modal.present();
    });
  }

  public async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: MoreButtonComponent,
      event: ev,
      componentProps: { page: this }
    });
    return await popover.present();
  }

}
