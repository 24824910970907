import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RightsPageRoutingModule } from './rights-routing.module';

import { RightsPage } from './rights.page';
import { RighteditDialogModule } from '@dmonitor/dialogs/rightedit/rightedit-dialog.component.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RightsPageRoutingModule,
    TranslateModule.forChild(),
    RighteditDialogModule
  ],
  declarations: [RightsPage]
})
export class RightsPageModule {}
