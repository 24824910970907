import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services';
import { Company, County, Site, Equipment, PrivilegeArea, PrivilegesEnum } from '@dmonitor/shared/dto';
import { ModalController, ToastController, LoadingController, IonRefresher } from '@ionic/angular';
import { RighteditDialogComponent } from '@dmonitor/dialogs/rightedit/rightedit-dialog.component';
import { OverlayEventDetail } from '@ionic/core';
import { CompanyPrivilege } from '@dmonitor/shared/dto/companyprivilege.dto';
import { CountyPrivilege } from '@dmonitor/shared/dto/countyprivilege.dto';
import { SitePrivilege } from '@dmonitor/shared/dto/siteprivilege.dto';
import { EquipmentPrivilege } from '@dmonitor/shared/dto/equipmentprivilege.dto';
import { Privileges } from '@dmonitor/shared/dto/privileges.dto';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper, BaseComponent } from '../shared';
import { CompaniesResponse } from '@dmonitor/shared/response';
@Component({
  selector: 'app-rights',
  templateUrl: './rights.page.pug',
  styleUrls: ['./rights.page.sass'],
})
export class RightsPage extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = 'page.user_rights';
  @ViewChild(IonRefresher) public refresher: IonRefresher;
  private userId: string;
  private areaId: string;
  private companyId: string;
  private countyId: string;
  private siteId: string;

  private companies: Array<Company> = null;
  private counties: Array<County> = null;
  private sites: Array<Site> = null;
  private equipments: Array<Equipment> = null;
  private userCompanies: Array<Company> = null;
  private userCounties: Array<County> = null;
  private userSites: Array<Site> = null;
  private userequipments:Array<Equipment> = null;
  public backLink: string;
  public homeLink: string;
  public PrivilegeArea = PrivilegeArea;
  private url : string;
  constructor(
    private route: ActivatedRoute,
    toastController: ToastController,
    loadingController: LoadingController,
    translateService: TranslateService,

    private modalController: ModalController,
    private dataService: DataService
  ) {
    super(toastController, loadingController, translateService);
  }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get("userId");
    this.areaId = this.route.snapshot.paramMap.get("areaId");
    this.companyId = this.route.snapshot.paramMap.get("companyId");
    this.countyId = this.route.snapshot.paramMap.get("countyId");
    this.siteId = this.route.snapshot.paramMap.get("siteId");
    this.presentLoading();
    this.setBackUrl();
    this.initUserData();
  }


  public doRefresh() {
    this.initUserData();
  }
  private refreshComplete(): void {
    if (this.refresher == null) {
      return;
    }
    this.refresher.complete();

  }


  private initUserData() {
    this.dataService.UserService.getUserData(this.userId).subscribe((res) => {
      this.homeLink = `/${UrlHelper.URL_USER}/${this.userId}`;
      if (this.areaId == `${PrivilegeArea.COMPANY}`) {
        this.url = `/${UrlHelper.URL_RIGHTS}/${this.userId}/${PrivilegeArea.COUNTY}/`;
        this.userCompanies = res.result.companies;
        this.initCompanyList();
      } else if (this.areaId == `${PrivilegeArea.COUNTY}`) {
        this.url = `/${UrlHelper.URL_RIGHTS}/${this.userId}/${PrivilegeArea.SITE}/${this.companyId}/`;
        this.userCounties = res.result.counties;
        this.initCountyList();
      } else if (this.areaId == `${PrivilegeArea.SITE}`) {
        this.userSites = res.result.sites;
        this.url = `/${UrlHelper.URL_RIGHTS}/${this.userId}/${PrivilegeArea.EQUIPMENT}/${this.companyId}/${this.countyId}/`;
        this.initSiteList();
      } else if (this.areaId == `${PrivilegeArea.EQUIPMENT}`) {
        this.userequipments = res.result.equipments;
        this.initEquipmentList();
      }
    });
  }

  private initCompanyList() {
    this.dataService.CompanyService.getAllCompanies().subscribe((resp: CompaniesResponse) => {
      this.companies = resp.result;
      this.companies.forEach((comp: Company) => {
        comp.privilege_id = `${PrivilegesEnum.NONE}`;
        this.userCompanies.forEach((priv: Company) => {
          if (priv.id == comp.id) {
            comp.privilege_id = priv.privilege_id;
            return;
          }
        });
      });

      this.dismissLoading();
      this.refreshComplete();
    });

  }

  private initCountyList(): void {
    this.dataService.CountyService.getCountiesByCompanyArray([Number(this.companyId)]).subscribe((res) => {
      this.counties = res.result;

      this.counties.forEach((c: County) => {
        c.privilege_id = `${PrivilegesEnum.NONE}`;
        this.userCounties.forEach((priv: County) => {
          if (priv.id == c.id) {
            c.privilege_id = priv.privilege_id;
            return;
          }
        });
      });
      this.dismissLoading();
      this.refreshComplete();
    });
  }

  private initSiteList(): void {

    this.dataService.SiteService.getAllSitesForCompanies([Number(this.companyId)]).subscribe((res) => {
      this.sites = res.result.filter(s => s.county_id == this.countyId);
      this.sites.forEach((s: Site) => {
        s.privilege_id = `${PrivilegesEnum.NONE}`;
        this.userSites.forEach((priv: Site) => {
          if (priv.id == s.id) {
            s.privilege_id = priv.privilege_id;
            return;
          }
        });
      });
      this.dismissLoading();
      this.refreshComplete();
    });
  }

  private initEquipmentList(): void {
    this.dataService.EquipmentService.getAllEquipments([this.siteId]).subscribe((res) => {
      this.equipments = res.result;
      this.equipments.forEach((eq: Equipment) => {
        eq.privilege_id = `${PrivilegesEnum.NONE}`;
        this.userequipments.forEach((priv: Equipment) => {
          if (priv.id == eq.id) {
            eq.privilege_id = priv.privilege_id;
            return;
          }
        });
      });
      this.dismissLoading();
      this.refreshComplete();
    });
  }

  private setBackUrl() {
    switch (this.areaId) {
      case `${PrivilegeArea.SITE}`:
        this.backLink = `/${UrlHelper.URL_RIGHTS}/${this.userId}/${PrivilegeArea.COUNTY}/${this.companyId}`;
        break;
      case `${PrivilegeArea.COUNTY}`:
        this.backLink = `/${UrlHelper.URL_RIGHTS}/${this.userId}/${PrivilegeArea.COMPANY}`;
        break;
      case `${PrivilegeArea.COMPANY}`:
        this.backLink = `/${UrlHelper.URL_USER}/${this.userId}`;
        break;
      case `${PrivilegeArea.EQUIPMENT}`:
        this.backLink = `/${UrlHelper.URL_RIGHTS}/${this.userId}/${PrivilegeArea.SITE}/${this.companyId}/${this.countyId}`;
        break;
      default:
        this.backLink = `/${UrlHelper.URL_USER}/${this.userId}`;
    }
    this.log(this.backLink);
  }

  private getCurrentRightForItem(id: string) {
    let privilege = `${PrivilegesEnum.NONE}`;
    switch (this.areaId) {
      case `${PrivilegeArea.SITE}`:
        this.sites.forEach((priv: Site) => {
          if (priv.id == id) {
            privilege = priv.privilege_id;
          }
        });
        break;
      case `${PrivilegeArea.COUNTY}`:
        this.counties.forEach((priv: County) => {
          if (priv.id == id) {
            privilege = priv.privilege_id;
          }
        });
        break;
      case `${PrivilegeArea.COMPANY}`:
        this.companies.forEach((priv: Company) => {
          if (priv.id == id) {
            privilege = priv.privilege_id;
          }
        });
        break;
      case `${PrivilegeArea.EQUIPMENT}`:
        //debugger;
        this.equipments.forEach((priv: Equipment) => {
          if (priv.id == id) {
            privilege = priv.privilege_id;
          }
        });
        break;
    }
    return privilege;
  }

  public async openRightEditModal(itemId: string) {
    let privilege = this.getCurrentRightForItem(itemId);
    this.presentLoading();

    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: RighteditDialogComponent,
        componentProps: {
          uid: this.userId,
          itemid: itemId,
          aid: this.areaId,
          priv: privilege
        }
      });

    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
      if (detail !== null && detail.data == true) {
        this.initUserData();
      }
    });
    await modal.present();
  }

  public getRightName(privilegeId) {
    let privName = this.translate("right_none");
    if (privilegeId == `${PrivilegesEnum.EDIT}`) {
      privName = this.translate("right_edit");
    } else if (privilegeId == `${PrivilegesEnum.VIEW}`) {
      privName = this.translate("right_view");
    }
    return privName;
  }

}
