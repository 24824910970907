import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@dmonitor/shared/index';
import { ToastController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeviceColors, DeviceType } from '@dmonitor/sensors/sensors.type';
import {EquipmentService} from '@dmonitor/services/equipment.service';
import {TankDailyData, TankSetting} from '@dmonitor/shared/response/sensor/tank';

@Component({
  selector: 'tank-hourlydatagraph',
  templateUrl: 'hourlydatagraph.component.pug',
  styleUrls: ['hourlydatagraph.component.sass'],
})
export class HourlydataGraphComponent extends BaseComponent implements OnInit {
  protected TRANSLATE_KEY: string = "page.equipment.tank.hourlydata.graph";
  @Input() DailyData: TankDailyData[] = [];
  @Input() Settings: TankSetting;
  @Input() Day: string;
  @Input() EquipmentId: string;
  showXAxis = true;
  showYAxis = true;
  xAxisLabel = '';
  yAxisLabel = '';
  graphData = [];
  colorScheme = {
    domain: [DeviceColors.COLD_GRAPH_COLOR]
  };
  constructor(
    toastController: ToastController,
    loadingController: LoadingController,
    private equipmentService: EquipmentService,
    translateService: TranslateService) {
    super(toastController, loadingController, translateService);
  }
  public ngOnInit(): void {
        this.xAxisLabel = this.translate(this.TRANSLATE_KEY+'.xlabel');
        this.yAxisLabel = this.translate(this.TRANSLATE_KEY+'.ylabel');
        if (this.Settings.device_type == DeviceType.HOT_WATER) {
            this.colorScheme = {
                domain: [DeviceColors.HOT_GRAPH_COLOR]
            };
        }

      this.graphData = [];
      this.DailyData.forEach(d => {
          if(d.l != '-') {
              console.log(d)
              this.graphData.push({
                  name: d.from,
                  value: Number(d.l)
              });
          }
      });
      this.graphData = [
          {
              "name":"Hőmérséklet",
              "series":this.graphData,
          }
      ];
      this.graphData = [...this.graphData];
  }
  public chartLabel(val){
      val = Number(val);
      if(val== 0 ||val%4==0){
          return val
      }
      return '';
  }
    public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
        console.warn("aaaaa");
        console.warn(this.DailyData);
        this.graphData = [];
        this.DailyData.forEach(d => {
            if(d.l != '-') {
                console.log(d)
                this.graphData.push({
                    name: d.from,
                    value: Number(d.l)
                });
            }
        });
        this.graphData = [
            {
                "name":"Hőmérséklet",
                "series":this.graphData,
            }
        ];
        this.graphData = [...this.graphData];


    }

}
