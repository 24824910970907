import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Equipment } from '@dmonitor/shared/dto';
import { IonRefresher, LoadingController } from '@ionic/angular';
import { EquipmentService, DataService } from '@dmonitor/services/index';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BatteryLevel } from '../battery/battery.component';
import { SensorPowerSupply } from '../sensors.type';
import { PowerSupply } from '../powersupply/powersupply';
import { SignalStrength } from '../gsmsignalindicator/gsmsignalindicator.component';
import {
    TankData,
    TankParameters,
    TankResponse,
    TankSetting,
    DailyData,
    GaugeData,
    MonthlyData,
    MetadataBlock,
    HourlyData, TankDailyData
} from '@dmonitor/shared/response/sensor/tank';

@Component({
    selector: 'sensor-tank',
    templateUrl: 'tank.component.pug',
    styleUrls: ['tank.component.sass'],
})
export class TankComponent implements OnInit {
    @Input() public equipment: Equipment;
    @Input() public refreshEvent: Subject<void>;
    @Input() public silentRefreshEvent: Subject<void>;
    @ViewChild(IonRefresher) public refresher: IonRefresher;
    public equipmentData: TankResponse;
    public Data: TankData;
    public DailyData: TankDailyData[] = [];
    public ReadingsData: TankDailyData[] = [];
    public GaugeData: GaugeData;
    public Settings: TankSetting;
    public Parameters: TankParameters;
    public Type: number;
    public EquipmentPeriod: number = 0;
    public ServerPeriod: number = 0;
    public IsError: boolean = false;
    public IsLoading: boolean = false;
    public IsInit: boolean = true;
    public IsRefresh: boolean = false;
    public LoadDataInprogress: boolean = false;
    public Error: Error = null;
    public BatteryLevel = BatteryLevel.LNULL;
    public IsParameterView = false;
    public IsStatView = false;
    public IsMetadataView = false;
    public IsMonitoringView = false;
    public IsSimpleView = false;
    public IsAdvancedView = false;
    public IsMonthlyGraphStatView = false;
    public IsDailyGraphStatView = false;
    public IsReadingsView = false;
    public Metadata :MetadataBlock;
    public GsmSignalStregth:number = 0;
    public SensorPowerSupply = SensorPowerSupply;
    public loadingDialog;
    public HourlyData: HourlyData[];
    constructor(
        public loadingController: LoadingController,
        private dataService: DataService,
        private translateService: TranslateService,
        private equipmentService: EquipmentService,
    ) {

    }
    private translate(title: string): string {
        return this.translateService.instant(`page.equipment.tank.${title}`);
    }

    public async ngOnInit() {
        this.loadingDialog = await this.loadingController.create({
            message: this.translate('dialog_loading_msg')
        });
        this.silentRefreshEvent.subscribe(() => {
            if (this.LoadDataInprogress == false) {
                this.setEquipmentData();
            }
        });
        this.refreshEvent.subscribe(() => {
            if (this.LoadDataInprogress == false) {
                this.IsLoading = true;
                this.setEquipmentData();
            }
        });
        this.setEquipmentData();
    }

    public reload(): void {
        this.IsError = false;
        this.Error = null;
        this.setEquipmentData();
    }

    public doRefresh() {
        this.IsRefresh = true;
        //this.dataService.LoadEquipments();
        setTimeout(() => {
            this.setEquipmentData();
        }, 200);

    }
    private configureSetings():void{
        if (this.Settings == null) {
            this.Settings = {
                last_update: "",
                device_type: "",
                power_supply:SensorPowerSupply.UNKNOWN,
                unit: "",
                capacity: ""
            }
        }
    }

    private configureBatteryLevel():void{
        const powerSupplyType = Number(this.Settings.power_supply);
        if (this.Settings.power_supply == SensorPowerSupply.UNKNOWN) {
            return;
        }
        const powerSupply = new PowerSupply(powerSupplyType)
        //this.BatteryLevel = powerSupply.CalculateLevel(Number(this.Data.u));
    }

    private setEquipmentData() {
        console.log('setEquipmentdata');
        this.LoadDataInprogress = true;
        if (this.loadingDialog != null && this.IsLoading) {
            this.loadingDialog.present();
        }
        if (this.equipment.serial_num?.indexOf("/") == -1) {
            this.equipment.serial_num = this.equipment.serial_num.slice(0, 3) + "/" + this.equipment.serial_num.slice(3);
        }
        this.IsParameterView = this.dataService.LoggedInUser.viewSettings.enableParameters;
        this.IsStatView = this.dataService.LoggedInUser.viewSettings.enableStat;
        this.IsMetadataView = this.dataService.LoggedInUser.viewSettings.enableMetadata;
        this.IsAdvancedView = this.dataService.LoggedInUser.viewSettings.enableAdvancedview;
        this.IsMonthlyGraphStatView = this.dataService.LoggedInUser.viewSettings.enableMonthlyGraphStatview;
        this.IsDailyGraphStatView = this.dataService.LoggedInUser.viewSettings.enableDailyGraphStatview;
        this.IsSimpleView = this.dataService.LoggedInUser.viewSettings.enableSimpleview;
        this.IsMonitoringView = this.dataService.LoggedInUser.viewSettings.enableMonitoringview;
        this.IsReadingsView = this.dataService.LoggedInUser.viewSettings.enableReadingsview;

        this.equipmentService.getTankData(this.equipment.id).subscribe(
            (res) => {
                this.equipmentData = res.result;
                this.Settings = res.result.Settings;
                this.Metadata = res.result.Metadata;
                this.configureSetings();
                
                this.Data = res.result.Data;
                console.log(this.Data);
                this.DailyData = [];

                this.DailyData = res.result.HourlyData.sort((a, b) => {
                    return (a.from > b.from) ? 1 : ((b.from > a.from) ? -1 : 0);
                });


                //console.error(this.DailyData);
                this.ReadingsData = res.result.ReadingsData;

                this.Parameters = res.result.Parameters;
                console.log(this.Parameters);
                this.GaugeData = res.result.Gauge;
                this.IsError = false;

                this.IsInit = false;
                const now = new Date();
                const equipmentDate = this.Data != null ? Date.parse(this.Data.date) : Date.now();
                let deltaEquipment = now.getTime() - equipmentDate;
                const serverDate = this.Data != null ? Date.parse(this.Data.last_update) : Date.now();
                let deltaServer = now.getTime() - serverDate;
                if (this.Parameters.Period != null) {
                    deltaServer = Math.floor(deltaServer / 1000 / 60 / parseInt(this.Parameters.Period.Value));
                    deltaEquipment = Math.floor(deltaEquipment / 1000 / 60 / parseInt(this.Parameters.Period.Value));
                } else {
                    deltaServer = 0;
                    deltaEquipment = 0;
                }

                if (deltaServer > 1) {
                    this.ServerPeriod = deltaServer;
                }
                console.log('ServerPeriod: ' + this.ServerPeriod);
                if (deltaEquipment > 1) {
                    this.EquipmentPeriod = deltaEquipment;
                }
                console.log('EquipmentPeriod: ' + this.EquipmentPeriod);



                this.LoadDataInprogress = false;
                this.configureBatteryLevel();
                this.loadingDialogDismiss(500);
                this.ionRefresherComplete(500);

            },
            (err) => {
                this.IsError = true;
                this.IsInit = false;
                this.Error = err;
                this.LoadDataInprogress = false;
                this.loadingDialogDismiss()
                this.ionRefresherComplete();
            }

        );
    }

    private loadingDialogDismiss(timeout: number = 0): void {
        this.IsLoading = false;
        if (this.loadingDialog == null) {
            return;
        }
        setTimeout(() => {
            this.loadingDialog.dismiss();
        }, timeout);
    }

    private ionRefresherComplete(timeout: number = 0): void {
        this.IsRefresh = false;
        if (this.refresher == null) {
            return;
        }
        setTimeout(() => {
            this.refresher.complete();
        }, timeout);

    }
}
