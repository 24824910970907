import { Component, Input, OnInit } from '@angular/core';
import { MetadataBlock } from '@dmonitor/shared/response/sensor/hygrometer';
import { Equipment } from '@dmonitor/shared/dto';

@Component({
  selector: 'hygrometer-metadata',
  templateUrl: 'metadata.component.pug',
  styleUrls: ['metadata.component.sass'],
})
export class MetadataComponent implements OnInit {
  @Input() Metadata: MetadataBlock;
  @Input() Equipment: Equipment;
  @Input() StartExpanded: boolean = false;
  public Height:string;
  public expanded = false;

  public ngOnInit(): void {
    //debugger;
    console.log(this.Metadata);
    this.Height = `${this.Metadata.data.length * 80}px`;
    console.log(`EXPAND ${this.StartExpanded}`);
    this.expanded = this.StartExpanded;
  }
  
  public expandItem(item): void {
    if (this.expanded) {
      this.expanded = false;
    } else {
      this.expanded = true;
    }
  }

  public parseFloat(num: string) {
    return parseFloat(num).toPrecision(6);
  }

  public divisionOp(num, devider, precision = 3) {
    if (num == null) {
      return 0;
    }
    if (num == 0) return 0;
    return (num / devider).toFixed(precision);
  }
}
